import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {IonLoading} from '@ionic/angular/standalone';
import {Store} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {combineLatest} from 'rxjs';
import {selectLoadingState} from '../../shared/store/selectors/loading.selector';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-backdrop-loading',
  templateUrl: './backdrop-loading.component.html',
  styleUrls: ['./backdrop-loading.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonLoading, TranslateModule, CommonModule],
})
export class BackdropLoadingComponent {
  store = inject(Store);
  data$ = combineLatest({
    isLoading: this.store.select(selectLoadingState),
  });
}
