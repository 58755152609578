import {createSelector} from '@ngrx/store';
import {isAfter, isBefore, isSameDay, isSameWeek} from 'date-fns';
import {
  selectEmployeesState,
  selectEmployeesStateCurrentDayWorkMinutes,
  selectEmployeeStateCoreEndTime,
  selectEmployeeStateCurrentDay,
  selectEmployeeStateCurrentMonth,
  selectEmployeeStateCurrentWorkTimeSchemeCurrentEmployee,
  selectEmployeeStateStartedWorkRecordForCurrentDay,
  selectIsHoliday,
  selectIsWeekend,
} from './employees.selectors';
import {EStempButtonStatus} from 'src/app/features/home/components/hours-overview/types/stemp-button.enum';
import {selectTimeStateSystemTime} from '../../time/selectors/time.selectors';
import {EStempBtnDisabledStatus} from 'src/app/features/home/components/hours-overview/types/stemp-button-disabled.enum';
import {selectEmployeesStateData} from '../reducer';
import {selectLanguage} from '../../settings/selectors/settings.selectors';
import {ECanStartTimeRecord} from 'src/app/features/home/components/hours-overview/types/can-start-timerecord.enum';

export const selectEmployeeStateWorkRecordsOverview = createSelector(
  selectEmployeesState,
  selectTimeStateSystemTime,
  selectEmployeesStateCurrentDayWorkMinutes,
  (state, systemTime, currentDayWorkMinutes) => {
    const {currentEmployee, data} = state;
    let workRecordsOverview = {
      month: {
        workMinutes: 0,
        requiredWorkMinutes: 0,
      },
      week: {
        workMinutes: 0,
        requiredWorkMinutes: 0,
      },
      day: {
        workMinutes: 0,
        requiredWorkMinutes: 0,
      },
    };

    if (!currentEmployee || !data || !systemTime) return workRecordsOverview;

    let currentMonth = systemTime.getMonth() + 1;
    let currentYear = systemTime.getFullYear();

    data.years.forEach((y) => {
      if (y.year === currentYear) {
        y.months.forEach((m) => {
          if (m.month === currentMonth) {
            m.days.forEach((d) => {
              let day = new Date(d.date);
              day.setHours(23, 59, 59, 59);
              let today = new Date(systemTime);

              let employeeDayState = d.employees.find(
                (employee) => employee.employeeId === currentEmployee,
              );

              if (
                !employeeDayState ||
                employeeDayState.workMinutes == null ||
                employeeDayState.requiredWorkMinutes == null ||
                employeeDayState.absence
              )
                return;

              workRecordsOverview.month.requiredWorkMinutes +=
                employeeDayState.requiredWorkMinutes;

              if (isSameWeek(today, day)) {
                workRecordsOverview.week.requiredWorkMinutes +=
                  employeeDayState.requiredWorkMinutes;
              }

              if (isSameDay(today, day)) {
                workRecordsOverview.day.requiredWorkMinutes +=
                  employeeDayState.requiredWorkMinutes;
              }

              if (isBefore(day, today)) {
                workRecordsOverview.month.workMinutes +=
                  employeeDayState.workMinutes;

                if (isSameWeek(today, day)) {
                  workRecordsOverview.week.workMinutes +=
                    employeeDayState.workMinutes;
                }
              }
            });
          }
        });
      }
    });

    workRecordsOverview.day.workMinutes = currentDayWorkMinutes;
    workRecordsOverview.week.workMinutes += currentDayWorkMinutes;
    workRecordsOverview.month.workMinutes += currentDayWorkMinutes;

    return workRecordsOverview;
  },
);

export const selectEmployeeStateCanEditOverView = createSelector(
  selectEmployeesState,
  selectTimeStateSystemTime,
  selectEmployeeStateCurrentWorkTimeSchemeCurrentEmployee,
  (state, systemTime, workTimeScheme): ECanStartTimeRecord => {
    const {currentEmployee, data} = state;
    if (!currentEmployee || !data || !data.workingTimeRegulation)
      return ECanStartTimeRecord.UNDEFINED;

    let currentAccountState = data.employees.find
      ? data.employees.find(
          (employee) => employee.employee.id === currentEmployee,
        )
      : null;

    if (
      !currentAccountState ||
      (currentAccountState &&
        currentAccountState.employee.exitDate &&
        isAfter(systemTime, currentAccountState.employee.exitDate)) ||
      !currentAccountState.workStartDate ||
      !workTimeScheme ||
      (workTimeScheme &&
        workTimeScheme.end_date &&
        isAfter(systemTime, new Date(workTimeScheme.end_date)))
    )
      return ECanStartTimeRecord.NO_WORKTIMESCHEM;

    if (
      isBefore(new Date(currentAccountState.workStartDate), systemTime) ||
      isSameDay(new Date(currentAccountState.workStartDate), systemTime)
    ) {
      return ECanStartTimeRecord.CAN_START;
    }

    return ECanStartTimeRecord.BEFORE_WORKSTARTDATE;
  },
);

export const selectEmployeeStateCanStartWorkRecord = createSelector(
  selectEmployeesState,
  selectEmployeeStateCurrentDay,
  selectTimeStateSystemTime,
  selectIsWeekend,
  (state, currentDay, systemTime, isWeekEnd) => {
    const {currentEmployee, data} = state;
    if (!currentEmployee || !data.workingTimeRegulation || !currentDay) {
      return false;
    }

    const currentEmployeeDayState = currentDay.employees.find(
      (employee) => employee.employeeId === currentEmployee,
    );
    if (
      !currentEmployeeDayState ||
      currentEmployeeDayState.absence ||
      isWeekEnd
    ) {
      return false;
    }

    const {startHour, endHour, startMinute, endMinute} =
      data.workingTimeRegulation;
    const coreStartTime = new Date(systemTime);
    coreStartTime.setHours(startHour, startMinute, 0, 0);

    const coreEndTime = new Date(systemTime);
    coreEndTime.setHours(endHour, endMinute - 1, 0, 0);

    return coreStartTime <= systemTime && systemTime <= coreEndTime;
  },
);

export const selectEmployeeStateStempBtnDisabledStatus = createSelector(
  selectEmployeesState,
  selectEmployeeStateCurrentDay,
  selectTimeStateSystemTime,
  selectIsWeekend,
  selectIsHoliday,
  (
    state,
    currentDay,
    systemTime,
    isWeekEnd,
    isHoliday,
  ): EStempBtnDisabledStatus => {
    const {currentEmployee, data} = state;

    if (!currentEmployee || !data.workingTimeRegulation || !currentDay) {
      return EStempBtnDisabledStatus.UNKNOWN;
    }

    const currentEmployeeDayState = currentDay.employees.find(
      (employee) => employee.employeeId === currentEmployee,
    );

    if (!currentEmployeeDayState) return EStempBtnDisabledStatus.UNKNOWN;

    if (currentEmployeeDayState.absence) return EStempBtnDisabledStatus.ABSENCE;

    const {startHour, endHour, startMinute, endMinute} =
      data.workingTimeRegulation;
    const coreStartTime = new Date(systemTime);
    coreStartTime.setHours(startHour, startMinute, 0, 0);

    const coreEndTime = new Date(systemTime);
    coreEndTime.setHours(endHour, endMinute - 1, 0, 0);

    if (
      isBefore(systemTime, coreStartTime) ||
      isAfter(systemTime, coreEndTime) ||
      isWeekEnd ||
      isHoliday
    )
      return EStempBtnDisabledStatus.WORKING_TIME_REGULATION;

    return EStempBtnDisabledStatus.UNKNOWN;
  },
);

export const selectEmployeeStateStampButtonStatus = createSelector(
  selectEmployeeStateStartedWorkRecordForCurrentDay,
  selectEmployeeStateCanStartWorkRecord,
  (workRecord, canStartWorkRecord) => {
    if (workRecord) {
      return EStempButtonStatus.STOP_WORK_RECORD;
    } else if (canStartWorkRecord) {
      return EStempButtonStatus.START_WORK_RECORD;
    } else {
      return EStempButtonStatus.DISABLED;
    }
  },
);

export const selectEmployeesStateIsAfterCoreEndTime = createSelector(
  selectEmployeeStateCoreEndTime,
  selectTimeStateSystemTime,
  (coreEndTime, systemTime): boolean => {
    if (!coreEndTime) return false;

    return isAfter(systemTime, coreEndTime);
  },
);

export const selectEmployeesStateCoreTimesAsStrings = createSelector(
  selectEmployeesStateData,
  selectLanguage,
  (
    data,
    language,
  ): {
    startHour: string;
    startMinute: string;
    endHour: string;
    endMinute: string;
  } => {
    let coreTime = {
      startHour: '00',
      startMinute: '00',
      endHour: '00',
      endMinute: '00',
    };
    if (!data.workingTimeRegulation) return coreTime;
    const {startHour, startMinute, endHour, endMinute} =
      data.workingTimeRegulation;

    if (language != 'en') {
      coreTime.startHour = String(startHour).padStart(2, '0');
      coreTime.startMinute = String(startMinute).padStart(2, '0');
      coreTime.endHour = String(endHour).padStart(2, '0');
      coreTime.endMinute = String(endMinute).padStart(2, '0');
    } else {
      coreTime.startHour =
        startHour > 12 ? String(startHour - 12) : String(startHour);
      coreTime.startMinute = String(startMinute).padStart(2, '0');
      coreTime.endHour = endHour > 12 ? String(endHour - 12) : String(endHour);
      coreTime.endMinute = String(endMinute).padStart(2, '0');
      coreTime.startHour = coreTime.startHour.replace(/^0+/, '');
      coreTime.endHour = coreTime.endHour.replace(/^0+/, '');
    }

    return coreTime;
  },
);
