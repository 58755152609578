import {HttpClient, HttpResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ISettings} from '../types/settings.interfaces';
import {INGXLoggerMetadata, NGXLogger} from 'ngx-logger';
import {EStorageKey} from 'src/app/shared/enums/storage-key.enum';
import {format} from 'date-fns-tz';
import {environment} from 'src/environments/environment';
import {StorageService} from 'src/app/shared/services/storage.service';
import {Platform} from '@ionic/angular';
import {Device} from '@capacitor/device';
import {EMessageType} from 'src/app/shared/enums/message-type.enum';
import {alertActions} from 'src/app/features/alert/store/actions';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  logger = inject(NGXLogger);
  private deviceID: any;
  private deviceInfo: any;
  private deviceBatteryInfo: any;
  private deviceLanguageCode: any;

  constructor(
    private store: Store,
    private platform: Platform,
    private storage: StorageService,
    private http: HttpClient,
  ) {
    this.platform.ready().then(() => {
      this.getDeviceID().then((val) => {
        this.deviceID = val;
      });

      this.getDeviceInfo().then((val) => {
        this.deviceInfo = val;
      });

      this.getBatteryInfo().then((val) => {
        this.deviceBatteryInfo = val;
      });

      this.getDeviceLanguageCode().then((val) => {
        this.deviceLanguageCode = val;
      });
    });
  }

  saveSettingsInLocaleStore(settings: ISettings): Promise<void> {
    return this.storage.set(EStorageKey.SETTINGS, settings);
  }

  saveLogsInLocaleStore(logs: INGXLoggerMetadata[]): Promise<void> {
    return this.storage.set(EStorageKey.LOGS, logs);
  }

  sendLogsToServer(
    message: string,
    serverTime: Date,
    logs: INGXLoggerMetadata[],
    account: string,
    language: string,
  ): Observable<HttpResponse<any>> {
    const body = {
      system: {
        user: account ? account : 'undefined',
        appVersion: environment.version + ' Build ' + environment.buildNumber,
        appDate: format(serverTime, 'yyyy-MM-dd HH:mm:ssXXX', {
          timeZone: 'Europe/Berlin',
        }),
        id: this.deviceID?.uuid || 'undefined',
        name: this.deviceInfo?.name || 'undefined',
        isVirtual: this.deviceInfo?.isVirtual?.toString() || 'undefined',
        manufacturer: this.deviceInfo?.manufacturer || 'undefined',
        model: this.deviceInfo?.model || 'undefined',
        operatingSystem: this.deviceInfo?.operatingSystem || 'undefined',
        osVersion: this.deviceInfo?.osVersion || 'undefined',
        platform: this.deviceInfo?.platform || 'undefined',
        memUsed: this.deviceInfo?.memUsed?.toString() || 'undefined',
        webViewVersion: this.deviceInfo?.webViewVersion || 'undefined',
        realDiskFree: this.deviceInfo?.realDiskFree?.toString() || 'undefined',
        realDiskTotal:
          this.deviceInfo?.realDiskTotal?.toString() || 'undefined',
        batteryLevel: this.deviceInfo?.batteryLevel?.toString() || 'undefined',
        isCharging:
          this.deviceBatteryInfo?.isCharging?.toString() || 'undefined',
        systemLanguageCode: this.deviceLanguageCode?.value || 'undefined',
        appLanguageCode: language ? language : 'undefined',
        comment: message || 'undefined',
      },
      logs: logs,
    };

    return this.http.post<any>(
      environment.apiUrl + 'logs',
      JSON.stringify(body),
      {
        headers: {'Content-Type': 'application/json'},
        observe: 'response',
      },
    );
  }

  private async getDeviceID() {
    try {
      return await Device.getId();
    } catch (error: any) {
      this.logger.info(error.message);
      return undefined;
    }
  }

  private async getDeviceInfo() {
    try {
      return await Device.getInfo();
    } catch (error: any) {
      this.logger.info(error.message);
      return undefined;
    }
  }

  private async getBatteryInfo() {
    try {
      return await Device.getBatteryInfo();
    } catch (error: any) {
      this.logger.info(error.message);
      return undefined;
    }
  }

  private async getDeviceLanguageCode() {
    try {
      return await Device.getLanguageCode();
    } catch (error: any) {
      this.logger.info(error.message);
      return undefined;
    }
  }
}
