import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {IEmployeesDataState} from './types/employees-data-state';
import {IWorkingTimeRegulation} from './types/work-time-regulation.interface';
import {ICoreTimeRequest} from './types/core-time-request.interface';

export const employeeActions = createActionGroup({
  source: 'Employee',
  events: {
    'Fetch employees': emptyProps,
    'Success fetching employees': props<{
      payload: IEmployeesDataState;
    }>(),
    'Failed fetching employees': emptyProps,
    'Set current employee': props<{payload: number | null}>(),
    'Set alias': props<{payload: number}>(),
    'Remove alias': emptyProps,
    'Fetch today': props<{payload: Date}>(),
    'Set today': props<{payload: Date}>(),
    SetCurrentYear: props<{payload: number}>(),
    'Set Month Overview': props<{payload: {month: number; year: number}}>(),
    'Reset Month Overview': emptyProps,
    'Set Work Record': props<{payload: number}>(),
    ChangeCoreTime: props<{payload: ICoreTimeRequest}>(),
    ChangeCoreTimeSuccess: emptyProps,
    ChangeCoreTimeFailed: emptyProps,
    'Stop loading': emptyProps,
    Logout: emptyProps,
  },
});
