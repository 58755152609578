import {createSelector} from '@ngrx/store';
import {selectAccountLoading} from '../account/reducer';
import {selectWorkRecordIsLoading} from '../work-record/reducer';
import {selectIsLoadingBackdropEmployeeState} from '../employees/reducer';
import {selectIsLoadingWorkTimeSchemeState} from '../worktimescheme/reducer';
import {selectIsLoadingAbsenceState} from '../absence/reducer';

export const selectLoadingState = createSelector(
  selectAccountLoading,
  selectWorkRecordIsLoading,
  selectIsLoadingBackdropEmployeeState,
  selectIsLoadingWorkTimeSchemeState,
  selectIsLoadingAbsenceState,
  (
    accountLoading,
    workRecordLoading,
    employeeLoading,
    workTimeSchemeLoading,
    absenceState,
  ): boolean => {
    return (
      accountLoading ||
      workRecordLoading ||
      employeeLoading ||
      workTimeSchemeLoading ||
      absenceState
    );
  },
);
