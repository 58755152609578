import {createActionGroup, emptyProps, props} from '@ngrx/store';

export const menuActions = createActionGroup({
  source: 'Menu',
  events: {
    SelectMenuItem: props<{id: number; path: string}>(),
    ToggleMenuItemGroup: props<{id: number; collapsed: boolean}>(),
    AliasView: emptyProps,
    Reset: emptyProps,
  },
});
