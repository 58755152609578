import {createFeature, createReducer, on} from '@ngrx/store';
import {splashScreenActions} from './actions';
import {ISplashScreenState} from './types/splash-screen-state.interface';

const initialState: ISplashScreenState = {
  visible: false,
};

const splashScreenFeature = createFeature({
  name: 'splashScreen',
  reducer: createReducer(
    initialState,
    on(splashScreenActions.show, (state) => ({
      ...state,
      visible: true,
    })),
    on(splashScreenActions.hide, (state) => ({
      ...state,
      visible: false,
    })),
  ),
});

export const {
  name: splashScreenFeatureKey,
  reducer: splashScreenReducer,
  selectSplashScreenState,
  selectVisible: selectSplashScreenIsVisible,
} = splashScreenFeature;
