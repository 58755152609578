import {Routes} from '@angular/router';
import {MenuDeactivateGuard} from './shared/guards/menu.guard';

export const routes: Routes = [
  {
    path: '',
    canDeactivate: [MenuDeactivateGuard],
    loadChildren: () =>
      import('./features/menu/menu.routes').then((m) => m.routes),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/login/login.routes').then((m) => m.routes),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
