import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {EMessageType} from '../enums/message-type.enum';
import {Store} from '@ngrx/store';
import {accountActions} from '../store/account/actions';
import {alertActions} from 'src/app/features/alert/store/actions';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorHandlerService {
  store = inject(Store);
  logger = inject(NGXLogger);
  translateService = inject(TranslateService);

  handleError(httpError: HttpErrorResponse): void {
    let errorMessage: string = this.translateService.instant(
      'CORE.MESSAGES.ERRORS.UNKNOWN',
    );
    let messageType: EMessageType = EMessageType.ERROR;
    let id: string | null = null;

    this.logger.error(httpError);

    if (httpError.url?.includes('workrecords')) {
      id = 'CORE.MESSAGES.ERRORS.WORK_RECORDS';
      errorMessage = this.translateService.instant(
        'CORE.MESSAGES.ERRORS.WORK_RECORDS',
      );
    }

    if (httpError?.error?.error) {
      switch (httpError.error.error) {
        case 'workTimeScheme_date_overlap':
          errorMessage = this.translateService.instant(
            'CORE.MESSAGES.WARNINGS.WORK_TIME_SCHEME_DATE_OVERLAP',
          );
          id = 'CORE.MESSAGES.WARNINGS.WORK_TIME_SCHEME_DATE_OVERLAP';
          messageType = EMessageType.WARNING;
          break;
        case 'workTimeScheme_end_date':
          errorMessage = this.translateService.instant(
            'CORE.MESSAGES.WARNINGS.WORK_TIME_SCHEME_END_DATE_RULE',
          );
          id = 'CORE.MESSAGES.WARNINGS.WORK_TIME_SCHEME_END_DATE_RULE';
          messageType = EMessageType.WARNING;
          break;
        case 'account_expired':
        case 'account_inactive':
          errorMessage = this.translateService.instant(
            'CORE.MESSAGES.ERRORS.UNAUTHORIZED_OR_INACTIVE',
          );
          id = 'CORE.MESSAGES.ERRORS.UNAUTHORIZED_OR_INACTIVE';
          messageType = EMessageType.INFORMATION;
          this.store.dispatch(accountActions.sessionExpired());
          break;
        case 'invalid_grant':
          errorMessage = this.translateService.instant(
            'CORE.MESSAGES.ERRORS.INCORRECT_CREDENTIALS',
          );
          id;
          messageType = EMessageType.INFORMATION;
          break;
        case 'invalid_request':
        case 'Unauthenticated.':
          errorMessage = this.translateService.instant(
            'CORE.MESSAGES.ERRORS.SESSION_EXPIRED',
          );
          id = 'CORE.MESSAGES.ERRORS.SESSION_EXPIRED';
          messageType = EMessageType.INFORMATION;
          this.store.dispatch(accountActions.sessionExpired());
          break;
        default:
          this.logger.warn('Unhandled error code:', httpError.error.error);
      }
    }

    if (
      httpError?.error?.error?.email &&
      httpError.error.error.email.length > 0
    ) {
      switch (httpError.error.error.email[0]) {
        case 'The email has already been taken.':
          errorMessage = this.translateService.instant(
            'CORE.MESSAGES.ERRORS.EMAIL_ADDRESS_TAKEN',
          );
          id = 'CORE.MESSAGES.ERRORS.EMAIL_ADDRESS_TAKEN';
          messageType = EMessageType.ERROR;
          break;
        case 'The selected email is invalid.':
          errorMessage = this.translateService.instant(
            'CORE.MESSAGES.ERRORS.EMAIL_ADDRESS_UNKNOWN',
          );
          id = 'CORE.MESSAGES.ERRORS.EMAIL_ADDRESS_UNKNOWN';
          messageType = EMessageType.INFORMATION;
          break;
        default:
          this.logger.warn('Unhandled error code:', httpError.error.error);
      }
    }

    this.store.dispatch(
      alertActions.showAlert({message: errorMessage, messageType, id}),
    );
  }
}
