import {inject, Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {Platform} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {filter, map} from 'rxjs';
import {alertActions} from 'src/app/features/alert/store/actions';
import {EMessageType} from '../enums/message-type.enum';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  swUpdate = inject(SwUpdate);
  store = inject(Store);
  platform = inject(Platform);
  logger = inject(NGXLogger);
  translateService = inject(TranslateService);

  checkUpdates() {
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })),
      )
      .subscribe((evt) => {
        const availableVersion =
          evt.available.appData &&
          (evt.available.appData as {version: string}).version &&
          (evt.available.appData as {buildNumber: string}).buildNumber
            ? (evt.available.appData as {version: string}).version +
              ' Build ' +
              (evt.available.appData as {buildNumber: string}).buildNumber
            : 'undefined';
        const currentVersion =
          evt.current.appData &&
          (evt.current.appData as {version: string}).version &&
          (evt.current.appData as {buildNumber: string}).buildNumber
            ? (evt.current.appData as {version: string}).version +
              ' Build ' +
              (evt.current.appData as {buildNumber: string}).buildNumber
            : 'undefined';
        this.logger.info(
          `New pwa version found: Current version is ${currentVersion} and available version is ${availableVersion}`,
        );
        this.store.dispatch(
          alertActions.showAlert({
            message: this.translateService.instant(
              'CORE.MESSAGES.NOTE_UPDATE',
              {version: availableVersion},
            ),
            messageType: EMessageType.UPDATE,
            id: 'CORE.MESSAGES.NOTE_UPDATE',
          }),
        );
      });
  }
}
