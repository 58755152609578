import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {utcToZonedTime} from 'date-fns-tz';
import {environment} from 'src/environments/environment';
import {Store} from '@ngrx/store';
import {ITimeResponse} from '../types/time-response.interface';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  private endpoint = 'server-info';
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  fetchSystemTime(): Observable<ITimeResponse> {
    const url = environment.apiUrl + this.endpoint;
    return this.http.get<any>(url, {observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        const date = new Date(response.body.data.systemDate * 1000);
        const timezoneDate = utcToZonedTime(date, 'Europe/Berlin');
        return {
          systemTime: timezoneDate,
          customTimeDiff: response.body.data.customDateDiff,
        };
      }),
    );
  }

  updateServerCustomTimeOn(timeDiff: number): Observable<ITimeResponse> {
    const url = environment.apiUrl + this.endpoint;
    return this.http
      .post<any>(url, {time_diff: timeDiff}, {observe: 'response'})
      .pipe(
        map((response: any) => {
          const date = new Date(response.body.data.systemDate * 1000);
          const timezoneDate = utcToZonedTime(date, 'Europe/Berlin');
          return {
            systemTime: timezoneDate,
            customTimeDiff: response.body.data.customDateDiff,
          };
        }),
      );
  }

  getCustomTimeDiff(customTime: number, serverTime: number): number {
    let timeDiff = customTime - serverTime;
    let timeDiffSeconds = timeDiff % 60;

    if (timeDiffSeconds == 0) return timeDiff;

    if (timeDiff < 0) {
      let seconds = timeDiff % 60;
      timeDiff = timeDiff - seconds - 60;
    }

    if (timeDiff > 0) {
      let seconds = timeDiff % 60;
      timeDiff = timeDiff - seconds;
    }

    return timeDiff;
  }
}
