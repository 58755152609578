import {inject, Injectable} from '@angular/core';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {IWorkRecordStartEndTime} from '../../../../features/employees/components/work-record-form/types/workRecordStartEndTime.interface';
import {format, isAfter, isBefore, isSameDay} from 'date-fns';
import {IWorkRecord} from 'src/app/shared/types/work-record.interface';
import {map, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {utcToZonedTime} from 'date-fns-tz';
import {IEmployeesDataState} from 'src/app/shared/store/employees/types/employees-data-state';
import {convertApiEmployeeData} from 'src/app/shared/utils/util.functions';
import {Store} from '@ngrx/store';
import {selectTimeStateCurrentYear} from '../../time/selectors/time.selectors';

@Injectable({
  providedIn: 'root',
})
export class WorkRecordService {
  http = inject(HttpClient);
  store = inject(Store);
  year: number = new Date().getFullYear();

  constructor() {
    this.store.select(selectTimeStateCurrentYear).subscribe((year) => {
      this.year = year;
    });
  }
  calculateStartEndTimes(
    systemTime: Date | null,
    date: Date | null,
    coreStartTime: Date | null,
    coreEndTime: Date | null,
  ): IWorkRecordStartEndTime {
    let data: IWorkRecordStartEndTime = {
      minStartTime: null,
      maxStartTime: null,
      minEndTime: null,
      maxEndTime: null,
      defaultStartTime: {hour: 7, minute: 0, second: 0},
    };

    if (!systemTime || !coreStartTime || !coreEndTime) return data;

    data.minStartTime = this.toTimeStruct(coreStartTime);
    data.minEndTime = this.updateTimeStruct(0, 1, 0, data.minStartTime);

    if (!date) date = new Date(systemTime);

    if (isSameDay(date, systemTime)) {
      if (coreEndTime && coreStartTime) {
        if (isBefore(systemTime, coreEndTime)) {
          let newEndTime = this.toTimeStruct(systemTime);
          data.maxEndTime = this.updateTimeStruct(0, 0, 0, newEndTime);
          data.maxStartTime = data.maxEndTime
            ? this.updateTimeStruct(0, 0, 0, data.maxEndTime)
            : null;
        } else {
          data.maxEndTime = this.toTimeStruct(coreEndTime);
          data.maxStartTime = data.maxEndTime
            ? this.updateTimeStruct(0, -1, 0, data.maxEndTime)
            : null;
        }
      } else {
        data.maxEndTime = {hour: 22, minute: 0, second: 0};
        data.maxStartTime = {hour: 21, minute: 59, second: 0};
      }
    } else {
      if (coreEndTime) {
        data.maxEndTime = this.toTimeStruct(coreEndTime);
        data.maxStartTime = data.maxEndTime
          ? this.updateTimeStruct(0, -1, 0, data.maxEndTime)
          : null;
      } else {
        data.maxEndTime = {hour: 22, minute: 0, second: 0};
        data.maxStartTime = {hour: 21, minute: 59, second: 0};
      }
    }

    if (
      coreStartTime &&
      coreEndTime &&
      isBefore(systemTime, coreEndTime) &&
      isAfter(systemTime, coreStartTime) &&
      isSameDay(date, systemTime)
    ) {
      data = {...data, defaultStartTime: this.toTimeStruct(systemTime)};
    } else {
      data = {...data, defaultStartTime: this.toTimeStruct(coreStartTime)};
    }

    return data;
  }

  addNewWorkRecord(workRecord: IWorkRecord): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'workrecords';
    const params = new HttpParams().set('year', this.year);
    return this.http
      .post<any>(
        url,
        {
          employee_id: workRecord.employee_id,
          editor_id: workRecord.editor_id,
          start_time: format(
            utcToZonedTime(workRecord.start_time, 'UTC'),
            'yyyy-MM-dd HH:mm:ss',
          ),
          end_time: workRecord.end_time
            ? format(
                utcToZonedTime(workRecord.end_time, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          comment: workRecord.comment,
          comment_editor: workRecord.comment_editor,
          notified: workRecord.notified,
          status: workRecord.status,
        },
        {params, observe: 'response'},
      )
      .pipe(
        map((response: HttpResponse<IEmployeesDataState | null>) => {
          if (response.body !== null) {
            let data: IEmployeesDataState = response.body;
            return convertApiEmployeeData(data);
          } else {
            throw new Error('Invalid response');
          }
        }),
      );
  }

  updateWorkRecord(workRecord: IWorkRecord): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'workrecords/' + workRecord.id;
    const params = new HttpParams().set('year', this.year);
    return this.http
      .patch<any>(
        url,
        {
          employee_id: workRecord.employee_id,
          editor_id: workRecord.editor_id,
          start_time: format(
            utcToZonedTime(workRecord.start_time, 'UTC'),
            'yyyy-MM-dd HH:mm:ss',
          ),
          end_time: workRecord.end_time
            ? format(
                utcToZonedTime(workRecord.end_time, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          comment: workRecord.comment,
          comment_editor: workRecord.comment_editor,
          notified: workRecord.notified,
          status: workRecord.status,
        },
        {params, observe: 'response'},
      )
      .pipe(
        map((response: HttpResponse<IEmployeesDataState | null>) => {
          if (response.body !== null) {
            let data: IEmployeesDataState = response.body;
            return convertApiEmployeeData(data);
          } else {
            throw new Error('Invalid response');
          }
        }),
      );
  }

  deleteWorkRecord(id: number): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'workrecords/' + id;
    const params = new HttpParams().set('year', this.year);
    return this.http.delete<any>(url, {params, observe: 'response'}).pipe(
      map((response: HttpResponse<IEmployeesDataState | null>) => {
        if (response.body !== null) {
          let data: IEmployeesDataState = response.body;
          return convertApiEmployeeData(data);
        } else {
          throw new Error('Invalid response');
        }
      }),
    );
  }

  private updateTimeStruct(
    hour: number,
    minute: number,
    second: number,
    sourceTime: NgbTimeStruct,
  ): NgbTimeStruct {
    let totalSeconds = sourceTime.second + second;
    let totalHours = sourceTime.hour + hour;
    let totalMinutes = sourceTime.minute + minute;
    if (totalMinutes < 0) {
      totalHours--;
      totalMinutes += 60;
    } else if (totalMinutes >= 60) {
      totalHours++;
      totalMinutes -= 60;
    }

    return {
      hour: totalHours,
      minute: totalMinutes,
      second: totalSeconds,
    };
  }

  private toTimeStruct(time: Date): NgbTimeStruct {
    return {
      hour: time.getHours(),
      minute: time.getMinutes(),
      second: 0,
    };
  }
}
