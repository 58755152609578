import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAuthData} from '../types/auth-data.interface';
import {selectAuthData} from '../store/account/reducer';
import {catchError, map, mergeMap, take, throwError} from 'rxjs';
import {accountActions} from '../store/account/actions';
import {AccountService} from '../store/account/services/account.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  const accountService = inject(AccountService);
  return store.select(selectAuthData).pipe(
    take(1),
    mergeMap((authData: IAuthData | null) => {
      if (authData) {
        req = req.clone({
          setHeaders: {
            Authorization: `${authData.token_type} ${authData.access_token}`,
          },
        });
      }
      return next(req).pipe(
        catchError((error) => {
          if (
            (error.status === 401 || error.error.code === 401) &&
            authData &&
            error.error.message !== 'The refresh token is invalid.'
          ) {
            return accountService.refreshAuthData(authData).pipe(
              map((newAuthData) => {
                store.dispatch(
                  accountActions.refreshAuthDataSuccess({
                    authData: newAuthData,
                  }),
                );
                return newAuthData;
              }),
              mergeMap((newAuthData) => {
                return next(
                  req.clone({
                    setHeaders: {
                      Authorization: `${newAuthData.token_type} ${newAuthData.access_token}`,
                    },
                  }),
                );
              }),
            );
          }
          return throwError(() => error);
        }),
      );
    }),
  );
};
