import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IAccountState} from '../types/account-state.interface';
import {selectAccount} from '../reducer';
import {EUserRole} from 'src/app/shared/enums/user-role.enum';

export const selectAccountState =
  createFeatureSelector<IAccountState>('account');

export const selectAccountFullName = createSelector(
  selectAccount,
  (account): string => {
    return account ? `${account.firstName} ${account.lastName}` : '';
  },
);

export const selectAccountRole = createSelector(
  selectAccount,
  (account): EUserRole | null => {
    if (!account) {
      return null;
    }

    return account.role;
  },
);
