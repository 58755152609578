import {createFeature, createReducer, on} from '@ngrx/store';
import {IAbsenceState} from './types/absence-state.interface';
import {absenceActions} from './actions';

const initialState: IAbsenceState = {
  absence: null,
  startDate: null,
  endDate: null,
  type: null,
  isLoading: false,
  isLoadingCard: false,
};

const absenceFeature = createFeature({
  name: 'absence',
  reducer: createReducer(
    initialState,
    on(
      absenceActions.setAbsence,
      absenceActions.setOpenAbsence,
      (state, action) => ({
        ...state,
        absence: action.payload,
        type: action.payload ? action.payload.type : null,
      }),
    ),
    on(absenceActions.setAbsenceType, (state, action) => ({
      ...state,
      type: action.payload,
    })),
    on(absenceActions.setDate, (state, action) => ({
      ...state,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    })),
    on(
      absenceActions.addAbsence,
      absenceActions.updateAbsence,
      absenceActions.deleteAbsence,
      absenceActions.deleteAbsenceCard,
      absenceActions.updateAbsenceStatus,
      (state) => ({
        ...state,
        isLoading: true,
      }),
    ),

    on(absenceActions.approveAbsenceCard, (state) => ({
      ...state,
      isLoadingCard: true,
    })),
    on(
      absenceActions.addAbsenceSuccess,
      absenceActions.updateAbsenceSuccess,
      absenceActions.deleteAbsenceSuccess,
      absenceActions.deleteAbsenceCardSuccess,
      absenceActions.approveAbsenceCardSuccess,
      absenceActions.updateAbsenceStatusSuccess,
      (state) => ({
        ...state,
        absence: null,
        type: null,
        isLoading: false,
        isLoadingCard: false,
      }),
    ),
    on(
      absenceActions.addAbsenceFailure,
      absenceActions.updateAbsenceFailure,
      absenceActions.deleteAbsenceFailure,
      (state) => ({
        ...state,
        isLoading: false,
        isLoadingCard: false,
      }),
    ),
    on(absenceActions.reset, () => initialState),
  ),
});

export const {
  name: absenceFeatureKey,
  reducer: absenceReducer,
  selectAbsence: selectAbsenceStateAbsence,
  selectType: selectAbsenceStateType,
  selectStartDate: selectAbsenceStateStartDate,
  selectEndDate: selectAbsenceStateEndDate,
  selectIsLoading: selectIsLoadingAbsenceState,
  selectIsLoadingCard: selectIsLoadingCardAbsenceState,
} = absenceFeature;
