import {Component, HostListener, inject} from '@angular/core';
import {IonApp, IonRouterOutlet} from '@ionic/angular/standalone';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {BackdropLoadingComponent} from './features/backdrop-loading/backdrop-loading.component';
import {AlertComponent} from './features/alert/alert.component';
import {SplashScreenComponent} from './features/splash-screen/splash-screen.component';
import {NGXLogger} from 'ngx-logger';
import {isThisISOWeek} from 'date-fns';
import {FlowControlService} from './shared/services/flow-control.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [
    IonApp,
    IonRouterOutlet,
    BackdropLoadingComponent,
    AlertComponent,
    SplashScreenComponent,
  ],
})
export class AppComponent {
  private flowControlService = inject(FlowControlService);
  private logger = inject(NGXLogger);
  private isBackground = false;
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.isAppInBackground();
  }
  constructor(faLibrary: FaIconLibrary) {
    faLibrary.addIconPacks(fas, far, fab);
  }

  isAppInBackground() {
    if (document.hidden) {
      this.isBackground = true;
      this.logger.info('App goes to background');
    } else {
      if (this.isBackground) {
        this.isBackground = false;
        this.logger.info('App is back to foreground');
        this.flowControlService.refresh();
      }
    }
  }
}
