import {inject, Injectable} from '@angular/core';
import {format} from 'date-fns';
import {map, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {utcToZonedTime} from 'date-fns-tz';
import {IEmployeesDataState} from 'src/app/shared/store/employees/types/employees-data-state';
import {convertApiEmployeeData} from 'src/app/shared/utils/util.functions';
import {IWorkTimeSchemeFormSubmitValues} from 'src/app/features/employees/components/worktimescheme-form/types/worktimescheme-form-submit-values.interface';
import {id} from 'date-fns/locale';
import {ICarryOverRecordFormSubmitValues} from 'src/app/features/employees/components/carryoverrecord-form/types/carryoverrecord-form-submit-values.interface';
import {Store} from '@ngrx/store';
import {selectTimeStateCurrentYear} from '../../time/selectors/time.selectors';

@Injectable({
  providedIn: 'root',
})
export class WorkTimeSchemeService {
  http = inject(HttpClient);
  store = inject(Store);
  year: number = new Date().getFullYear();

  constructor() {
    this.store.select(selectTimeStateCurrentYear).subscribe((year) => {
      this.year = year;
    });
  }
  addNewWorkTimeScheme(
    workTimeSchemeSubmitValues: IWorkTimeSchemeFormSubmitValues,
  ): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'worktimeschemes';
    const params = new HttpParams().set('year', this.year);
    return this.http
      .post<any>(
        url,
        {
          employee_id: workTimeSchemeSubmitValues.employee_id
            ? workTimeSchemeSubmitValues.employee_id
            : null,
          start_date: format(
            utcToZonedTime(workTimeSchemeSubmitValues.start_date!, 'UTC'),
            'yyyy-MM-dd HH:mm:ss',
          ),
          end_date: workTimeSchemeSubmitValues.end_date
            ? format(
                utcToZonedTime(workTimeSchemeSubmitValues.end_date, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          start_time: workTimeSchemeSubmitValues.start_time
            ? workTimeSchemeSubmitValues.start_time.hours +
              ':' +
              workTimeSchemeSubmitValues.start_time.minutes +
              ':' +
              workTimeSchemeSubmitValues.start_time.seconds
            : null,
          end_time: workTimeSchemeSubmitValues.end_time
            ? workTimeSchemeSubmitValues.end_time.hours +
              ':' +
              workTimeSchemeSubmitValues.end_time.minutes +
              ':' +
              workTimeSchemeSubmitValues.end_time.seconds
            : null,
          working_hours: workTimeSchemeSubmitValues.working_hours,
          vacation_days: workTimeSchemeSubmitValues.vacation_days,
          monday: workTimeSchemeSubmitValues.monday ? 1 : 0,
          tuesday: workTimeSchemeSubmitValues.tuesday ? 1 : 0,
          wednesday: workTimeSchemeSubmitValues.wednesday ? 1 : 0,
          thursday: workTimeSchemeSubmitValues.thursday ? 1 : 0,
          friday: workTimeSchemeSubmitValues.friday ? 1 : 0,
        },
        {params, observe: 'response'},
      )
      .pipe(
        map((response: HttpResponse<IEmployeesDataState | null>) => {
          if (response.body !== null) {
            let data: IEmployeesDataState = response.body;
            return convertApiEmployeeData(data);
          } else {
            throw new Error('Invalid response');
          }
        }),
      );
  }

  updateWorkTimeScheme(
    workTimeSchemeSubmitValues: IWorkTimeSchemeFormSubmitValues,
  ): Observable<IEmployeesDataState> {
    const url =
      environment.apiUrl + 'worktimeschemes/' + workTimeSchemeSubmitValues.id;
    const params = new HttpParams().set('year', this.year);
    return this.http
      .patch<any>(
        url,
        {
          id: workTimeSchemeSubmitValues.id,
          employee_id: workTimeSchemeSubmitValues.employee_id
            ? workTimeSchemeSubmitValues.employee_id
            : null,
          start_date: format(
            utcToZonedTime(workTimeSchemeSubmitValues.start_date!, 'UTC'),
            'yyyy-MM-dd HH:mm:ss',
          ),
          end_date: workTimeSchemeSubmitValues.end_date
            ? format(
                utcToZonedTime(workTimeSchemeSubmitValues.end_date, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          start_time: workTimeSchemeSubmitValues.start_time
            ? workTimeSchemeSubmitValues.start_time.hours +
              ':' +
              workTimeSchemeSubmitValues.start_time.minutes +
              ':' +
              workTimeSchemeSubmitValues.start_time.seconds
            : null,
          end_time: workTimeSchemeSubmitValues.end_time
            ? workTimeSchemeSubmitValues.end_time.hours +
              ':' +
              workTimeSchemeSubmitValues.end_time.minutes +
              ':' +
              workTimeSchemeSubmitValues.end_time.seconds
            : null,
          working_hours: workTimeSchemeSubmitValues.working_hours,
          vacation_days: workTimeSchemeSubmitValues.vacation_days,
          monday: workTimeSchemeSubmitValues.monday ? 1 : 0,
          tuesday: workTimeSchemeSubmitValues.tuesday ? 1 : 0,
          wednesday: workTimeSchemeSubmitValues.wednesday ? 1 : 0,
          thursday: workTimeSchemeSubmitValues.thursday ? 1 : 0,
          friday: workTimeSchemeSubmitValues.friday ? 1 : 0,
        },
        {params, observe: 'response'},
      )
      .pipe(
        map((response: HttpResponse<IEmployeesDataState | null>) => {
          if (response.body !== null) {
            let data: IEmployeesDataState = response.body;
            return convertApiEmployeeData(data);
          } else {
            throw new Error('Invalid response');
          }
        }),
      );
  }

  deleteWorkTimeScheme(id: number): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'worktimeschemes/' + id;
    const params = new HttpParams().set('year', this.year);
    return this.http.delete<any>(url, {params, observe: 'response'}).pipe(
      map((response: HttpResponse<IEmployeesDataState | null>) => {
        if (response.body !== null) {
          let data: IEmployeesDataState = response.body;
          return convertApiEmployeeData(data);
        } else {
          throw new Error('Invalid response');
        }
      }),
    );
  }

  addNewCarryOverRecord(
    values: ICarryOverRecordFormSubmitValues,
  ): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'carryoverrecord';
    const params = new HttpParams().set('year', this.year);
    return this.http
      .post<any>(
        url,
        {
          employee_id: values.employee_id ? values.employee_id : null,
          overtime: values.overtime ? values.overtime : 0,
          vacations: values.vacations ? values.vacations : 0,
        },
        {params, observe: 'response'},
      )
      .pipe(
        map((response: HttpResponse<IEmployeesDataState | null>) => {
          if (response.body !== null) {
            let data: IEmployeesDataState = response.body;
            return convertApiEmployeeData(data);
          } else {
            throw new Error('Invalid response');
          }
        }),
      );
  }

  updateCarryOverRecord(
    values: ICarryOverRecordFormSubmitValues,
  ): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'carryoverrecord/' + values.id;
    const params = new HttpParams().set('year', this.year);
    return this.http
      .patch<any>(
        url,
        {
          id: values.id ? values.id : null,
          employee_id: values.employee_id ? values.employee_id : null,
          overtime: values.overtime ? values.overtime : 0,
          vacations: values.vacations ? values.vacations : 0,
        },
        {params, observe: 'response'},
      )
      .pipe(
        map((response: HttpResponse<IEmployeesDataState | null>) => {
          if (response.body !== null) {
            let data: IEmployeesDataState = response.body;
            return convertApiEmployeeData(data);
          } else {
            throw new Error('Invalid response');
          }
        }),
      );
  }

  deleteCarryOverRecord(id: number): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'carryoverrecord/' + id;
    const params = new HttpParams().set('year', this.year);
    return this.http.delete<any>(url, {params, observe: 'response'}).pipe(
      map((response: HttpResponse<IEmployeesDataState | null>) => {
        if (response.body !== null) {
          let data: IEmployeesDataState = response.body;
          return convertApiEmployeeData(data);
        } else {
          throw new Error('Invalid response');
        }
      }),
    );
  }
}
