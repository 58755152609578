import {createFeature, createReducer, on} from '@ngrx/store';
import {IWorkTimeSchemeState} from './types/worktimescheme-state.interface';
import {workTimeSchemeActions} from './actions';

const initialState: IWorkTimeSchemeState = {
  employeeId: null,
  workTimeScheme: null,
  carryOverRecord: null,
  isLoading: false,
};

const workTimeSchemeFeature = createFeature({
  name: 'workTimeScheme',
  reducer: createReducer(
    initialState,
    on(workTimeSchemeActions.setEmployee, (state, action) => ({
      ...state,
      employeeId: action.payload,
    })),
    on(workTimeSchemeActions.setWorkTimeScheme, (state, action) => ({
      ...state,
      workTimeScheme: action.payload,
    })),
    on(workTimeSchemeActions.setCarryOverRecord, (state, action) => ({
      ...state,
      carryOverRecord: action.payload,
    })),
    on(
      workTimeSchemeActions.addWorkTimeScheme,
      workTimeSchemeActions.updateWorkTimeScheme,
      workTimeSchemeActions.deleteWorkTimeScheme,
      workTimeSchemeActions.deleteWorkTimeSchemeCard,
      workTimeSchemeActions.addCarryOverRecord,
      workTimeSchemeActions.updateCarryOverRecord,
      workTimeSchemeActions.deleteCarryOverRecord,
      (state) => ({
        ...state,
        isLoading: true,
      }),
    ),
    on(
      workTimeSchemeActions.addWorkTimeSchemeSuccess,
      workTimeSchemeActions.updateWorkTimeSchemeSuccess,
      workTimeSchemeActions.deleteWorkTimeSchemeSuccess,
      workTimeSchemeActions.deleteWorkTimeSchemeCardSuccess,
      (state) => ({
        ...state,
        workTimeScheme: null,
        isLoading: false,
      }),
    ),
    on(
      workTimeSchemeActions.addCarryOverRecordSuccess,
      workTimeSchemeActions.updateCarryOverRecordSuccess,
      workTimeSchemeActions.deleteCarryOverRecordSuccess,
      (state) => ({
        ...state,
        carryOverRecord: null,
        isLoading: false,
      }),
    ),
    on(
      workTimeSchemeActions.addWorkTimeSchemeFailure,
      workTimeSchemeActions.updateWorkTimeSchemeFailure,
      workTimeSchemeActions.deleteWorkTimeSchemeFailure,
      workTimeSchemeActions.deleteCarryOverRecordFailure,
      workTimeSchemeActions.addCarryOverRecordFailure,
      workTimeSchemeActions.updateCarryOverRecordFailure,
      (state) => ({
        ...state,
        isLoading: false,
      }),
    ),
    on(workTimeSchemeActions.reset, () => initialState),
  ),
});

export const {
  name: workTimeSchemeFeatureKey,
  reducer: workTimeSchemeReducer,
  selectEmployeeId: selectWorkTimeSchemeStateEmployeeId,
  selectWorkTimeScheme: selectWorkTimeSchemeStateWorkTimeScheme,
  selectCarryOverRecord: selectWorkTimeSchemeStateCarryOverRecord,
  selectIsLoading: selectIsLoadingWorkTimeSchemeState,
} = workTimeSchemeFeature;
