import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {IEmployeesDataState} from '../types/employees-data-state';
import {format, isSameDay, Locale} from 'date-fns';
import {IWorkRecord} from '../../../types/work-record.interface';
import {IDayState} from '../types/day-state.interface';
import {utcToZonedTime} from 'date-fns-tz';
import {convertApiEmployeeData} from 'src/app/shared/utils/util.functions';
import {
  EAbsenceStatus,
  EAbsencesType,
} from 'src/app/shared/enums/absences.enum';
import {TranslateService} from '@ngx-translate/core';
import {IAbsence} from 'src/app/shared/types/absence.interface';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {ICoreTimeRequest} from '../types/core-time-request.interface';
import {EUserRole} from 'src/app/shared/enums/user-role.enum';
import {Store} from '@ngrx/store';
import {de} from 'date-fns/locale';
import {selectLocaleCode} from '../../settings/selectors/settings.selectors';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  http = inject(HttpClient);
  translateService = inject(TranslateService);
  store = inject(Store);
  locale: Locale = de;
  constructor() {
    this.store.select(selectLocaleCode).subscribe((locale) => {
      this.locale = locale;
    });
  }

  fetchEmployees(year: number): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'employee-service';
    const params = new HttpParams().set('year', year);
    return this.http
      .get<IEmployeesDataState | null>(url, {params, observe: 'response'})
      .pipe(
        map((response: HttpResponse<IEmployeesDataState | null>) => {
          if (response.body !== null) {
            let data: IEmployeesDataState = response.body;
            return convertApiEmployeeData(data);
          } else {
            throw new Error('Invalid response');
          }
        }),
      );
  }

  changeCoreTime(
    coreTimeRequest: ICoreTimeRequest,
    year: number,
  ): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'worktimeregulation';
    const params = new HttpParams().set('year', year);
    return this.http
      .post<any>(
        url,
        {
          start_time: coreTimeRequest.startTime,
          end_time: coreTimeRequest.endTime,
          user_id: coreTimeRequest.userId,
        },
        {params, observe: 'response'},
      )
      .pipe(
        map((response: HttpResponse<IEmployeesDataState | null>) => {
          if (response.body !== null) {
            let data: IEmployeesDataState = response.body;
            return convertApiEmployeeData(data);
          } else {
            throw new Error('Invalid response');
          }
        }),
      );
  }

  getCurrentDayFromEmployeeState(
    data: IEmployeesDataState,
    today: Date | null,
  ): IDayState | null {
    if (!today) {
      return null;
    }

    const currentYear = data.years.find(
      (year) => year.year === today.getFullYear(),
    );
    if (!currentYear) {
      return null;
    }

    const currentMonth = currentYear.months.find(
      (month) => month.month === today.getMonth() + 1,
    );
    if (!currentMonth) {
      return null;
    }

    const currentDay = currentMonth.days.find((day) =>
      isSameDay(new Date(day.date), today),
    );
    if (!currentDay) {
      return null;
    }
    return currentDay;
  }

  getAbsenceType(type: EAbsencesType): string {
    var name = '';

    switch (type) {
      case 0:
        name = this.translateService.instant(
          'PAGES.ABSENT_RECORD.TITLE.VACATION',
        );
        break;
      case 1:
        name = this.translateService.instant(
          'PAGES.ABSENT_RECORD.TITLE.TIME_OFF',
        );
        break;
      case 2:
        name = this.translateService.instant(
          'PAGES.ABSENT_RECORD.TITLE.CONTINUING_EDUCATION',
        );
        break;
      case 3:
        name = this.translateService.instant(
          'PAGES.ABSENT_RECORD.TITLE.SICK_LEAVE',
        );
        break;
      case 4:
        name = this.translateService.instant('PAGES.ABSENT_RECORD.TITLE.LEAVE');
        break;
    }

    return name;
  }

  getAbsenceStatus(absence: IAbsence): string {
    var name = '';
    const status = absence.status;

    switch (status) {
      case 0:
        name = this.translateService.instant(
          'PAGES.ABSENT_RECORD.STATUS.REQUESTED',
        );
        break;
      case 1:
        if (absence.type == EAbsencesType.SICK_LEAVE) {
          name = this.translateService.instant(
            'PAGES.ABSENT_RECORD.STATUS.CHECKED',
          );
        } else {
          name = this.translateService.instant(
            'PAGES.ABSENT_RECORD.STATUS.APPROVED',
          );
        }
        break;
      case 2:
        name = this.translateService.instant(
          'PAGES.ABSENT_RECORD.STATUS.REJECTED',
        );
        break;
    }

    return name;
  }

  getAbsenceIconColor(type: EAbsencesType): string {
    var title = '';

    switch (type) {
      case 0:
        title = 'app-success';
        break;
      case 1:
        title = 'warning';
        break;
      case 2:
        title = 'app-primary';
        break;
      case 3:
        title = 'app-danger';
        break;
    }

    return title;
  }

  getAbsenceIconName(type: EAbsencesType): IconProp {
    var icon!: IconProp;

    switch (type) {
      case 0:
        icon = 'umbrella-beach';
        break;
      case 1:
        icon = 'business-time';
        break;
      case 2:
        icon = 'award';
        break;
      case 3:
        icon = 'briefcase-medical';
        break;
    }

    return icon;
  }

  getAbsenceStatusColor(status: EAbsenceStatus): string {
    var color: string = '';

    switch (status) {
      case 0:
        color = 'app-primary';
        break;
      case 1:
        color = 'app-success';
        break;
      case 2:
        color = 'app-danger';
        break;
    }
    return color;
  }

  getUserRole(role: EUserRole): string {
    switch (role) {
      case EUserRole.MANAGER:
        return this.translateService.instant('PAGES.MEMBERS.MANAGER');
      case EUserRole.EMPLOYEE:
      case EUserRole.TRAINEE:
        return this.translateService.instant('PAGES.MEMBERS.EDUCATOR');
      default:
        return 'Unknown';
    }
  }

  getAbsenceDateRange(absence: IAbsence): string {
    var date: string = '';

    const STARTDATE = format(absence.start_date, 'EEE dd.MM.yy', {
      locale: this.locale,
    });
    const ENDDATE = format(absence.end_date, 'EEE dd.MM.yy', {
      locale: this.locale,
    });

    if (isSameDay(absence.start_date, absence.end_date)) {
      return STARTDATE;
    }

    date = STARTDATE + ' - ' + ENDDATE;
    return date;
  }
}
