import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {IonSpinner} from '@ionic/angular/standalone';
import {Store} from '@ngrx/store';
import {combineLatest} from 'rxjs';
import {environment} from 'src/environments/environment';
import {selectSplashScreenIsVisible} from './store/reducer';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IonSpinner],
})
export class SplashScreenComponent {
  store = inject(Store);
  appName = environment.appName;

  data$ = combineLatest({
    isVisible: this.store.select(selectSplashScreenIsVisible),
  });
}
