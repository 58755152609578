import {inject, Injectable} from '@angular/core';
import {format} from 'date-fns';
import {map, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {utcToZonedTime} from 'date-fns-tz';
import {IEmployeesDataState} from 'src/app/shared/store/employees/types/employees-data-state';
import {convertApiEmployeeData} from 'src/app/shared/utils/util.functions';
import {IWorkTimeSchemeFormSubmitValues} from 'src/app/features/employees/components/worktimescheme-form/types/worktimescheme-form-submit-values.interface';
import {id} from 'date-fns/locale';
import {ICarryOverRecordFormSubmitValues} from 'src/app/features/employees/components/carryoverrecord-form/types/carryoverrecord-form-submit-values.interface';
import {Store} from '@ngrx/store';
import {selectTimeStateCurrentYear} from '../../time/selectors/time.selectors';
import {IAbsenceFormSubmitValues} from 'src/app/features/employees/components/absence-form/types/absence-form-submit-values.interface';
import {IAbsence} from 'src/app/shared/types/absence.interface';

@Injectable({
  providedIn: 'root',
})
export class AbsenceService {
  http = inject(HttpClient);
  store = inject(Store);
  year: number = new Date().getFullYear();

  constructor() {
    this.store.select(selectTimeStateCurrentYear).subscribe((year) => {
      this.year = year;
    });
  }
  addNewAbsence(
    values: IAbsenceFormSubmitValues,
  ): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'absences';
    const params = new HttpParams().set('year', this.year);
    return this.http
      .post<any>(
        url,
        {
          employee_id: values.employee_id ? values.employee_id : null,
          editor_id: values.editor_id ? values.editor_id : null,
          start_date: values.start_date
            ? format(
                utcToZonedTime(values.start_date, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          end_date: values.end_date
            ? format(
                utcToZonedTime(values.end_date, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          comment: values.comment,
          comment_editor: values.comment_editor,
          type: values.type,
          notified: values.notified,
          status: values.status,
        },
        {params, observe: 'response'},
      )
      .pipe(
        map((response: HttpResponse<IEmployeesDataState | null>) => {
          if (response.body !== null) {
            let data: IEmployeesDataState = response.body;
            return convertApiEmployeeData(data);
          } else {
            throw new Error('Invalid response');
          }
        }),
      );
  }

  updateAbsence(
    values: IAbsenceFormSubmitValues | IAbsence,
  ): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'absences/' + values.id;
    const params = new HttpParams().set('year', this.year);
    return this.http
      .patch<any>(
        url,
        {
          employee_id: values.employee_id ? values.employee_id : null,
          editor_id: values.editor_id ? values.editor_id : null,
          start_date: values.start_date
            ? format(
                utcToZonedTime(values.start_date, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          end_date: values.end_date
            ? format(
                utcToZonedTime(values.end_date, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          comment: values.comment,
          comment_editor: values.comment_editor,
          type: values.type,
          notified: values.notified,
          status: values.status,
        },
        {params, observe: 'response'},
      )
      .pipe(
        map((response: HttpResponse<IEmployeesDataState | null>) => {
          if (response.body !== null) {
            let data: IEmployeesDataState = response.body;
            return convertApiEmployeeData(data);
          } else {
            throw new Error('Invalid response');
          }
        }),
      );
  }

  deleteAbsence(id: number): Observable<IEmployeesDataState> {
    const url = environment.apiUrl + 'absences/' + id;
    const params = new HttpParams().set('year', this.year);
    return this.http.delete<any>(url, {params, observe: 'response'}).pipe(
      map((response: HttpResponse<IEmployeesDataState | null>) => {
        if (response.body !== null) {
          let data: IEmployeesDataState = response.body;
          return convertApiEmployeeData(data);
        } else {
          throw new Error('Invalid response');
        }
      }),
    );
  }
}
