import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {IUser} from '../../types/user.interface';
import {IUserFormValues} from 'src/app/features/users/components/user-form/types/user-form-values.interface';

export const usersActions = createActionGroup({
  source: 'users',
  events: {
    FetchUsers: emptyProps(),
    FetchUsersSuccess: props<{users: IUser[]}>(),
    FetchUsersFailure: emptyProps(),
    selectUser: props<{user: IUser}>(),
    removeSelectedUser: emptyProps(),
    addUser: props<{user: IUserFormValues}>(),
    addUserSuccess: props<{user: IUser}>(),
    addUserFailure: emptyProps(),
    updateUser: props<{id: number; user: IUserFormValues}>(),
    updateUserSuccess: props<{user: IUser}>(),
    updateUserFailure: emptyProps(),
    deleteUser: props<{id: number}>(),
    deleteUserSuccess: props<{id: number}>(),
    deleteUserFailure: emptyProps(),
  },
});
