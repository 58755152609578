export enum EAppRoutes {
  AUTH = '/auth',
  HOME = '/startseite',
  ACCOUNT = '/account',
  ACCOUNT_EDIT = ACCOUNT + '/bearbeiten',
  PASSWORD_RESET = ACCOUNT + '/passwort',
  USERS = '/mitglieder',
  USER = USERS + '/account',
  USER_EDIT = USER + '/bearbeiten',
  USER_NEW = USER + '/neu',
  EMPLOYEES = '/mitarbeiter',
  STAFF_TIME_REGULATION = EMPLOYEES + '/zeitregelung',
  STAFF_OPEN_ABSENCE = STAFF_TIME_REGULATION + '/abwesenheitsantrag',
  CORE_TIME_EDIT = STAFF_TIME_REGULATION + '/buchungszeiten/bearbeiten',
  WORKTIMESCHEMES = STAFF_TIME_REGULATION + '/alle',
  CARRYOVER_RECORD = STAFF_TIME_REGULATION + '/uebertrag',
  NEW_CARRYOVER_RECORD = STAFF_TIME_REGULATION + '/uebertrag/neu',
  NEW_WORKTIMESCHEME = STAFF_TIME_REGULATION + '/neu',
  EDIT_WORKTIMESCHEME = STAFF_TIME_REGULATION + '/bearbeiten',
  STAFF_TIME_ACCOUNTS = EMPLOYEES + '/arbeitszeiten',
  CALENDAR = EMPLOYEES + '/kalender',
  TIME_ACCOUNT = EMPLOYEES + '/zeitkonto',
  TIME_ACCOUNT_MONTHLY = TIME_ACCOUNT + '/arbeitszeiten',
  NEW_WORK_RECORD = TIME_ACCOUNT_MONTHLY + '/neu',
  EDIT_WORK_RECORD = TIME_ACCOUNT_MONTHLY + '/bearbeiten',
  ABSENCES = TIME_ACCOUNT + '/abwesenheiten',
  NEW_ABSENCE = ABSENCES + '/neu',
  EDIT_ABSENCE = ABSENCES + '/bearbeiten',
  SETTINGS = '/einstellungen',
}
