import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {alertActions} from './actions';
import {delay, map} from 'rxjs';

@Injectable()
export class AlertFeatureEffect {
  closeAlert$ = createEffect((actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(alertActions.closeAlert),
      delay(500),
      map(() => {
        return alertActions.reset();
      }),
    ),
  );
}
