import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {accountActions} from './actions';
import {
  catchError,
  delay,
  from,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {AccountService} from './services/account.service';
import {Store} from '@ngrx/store';
import {NavController} from '@ionic/angular/standalone';
import {HttpErrorHandlerService} from '../../services/http-error-handler.service';
import {StorageService} from '../../services/storage.service';
import {EStorageKey} from '../../enums/storage-key.enum';
import {splashScreenActions} from 'src/app/features/splash-screen/store/actions';
import {NGXLogger} from 'ngx-logger';
import {FlowControlService} from '../../services/flow-control.service';
import {alertActions} from 'src/app/features/alert/store/actions';
import {EMessageType} from '../../enums/message-type.enum';
import {TranslateService} from '@ngx-translate/core';
import {selectAccount} from './reducer';
import {EPasswordReset} from '../../enums/password-reset.enum';
import {EAppRoutes} from '../../enums/app-routes.enum';

@Injectable()
export class AccountFeatureEffect {
  loginEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      accountService = inject(AccountService),
      logger = inject(NGXLogger),
      httpErrorHandler = inject(HttpErrorHandlerService),
    ) => {
      return actions$.pipe(
        ofType(accountActions.startLogin),
        switchMap((action) => {
          return accountService
            .login({
              username: action.username,
              password: action.password,
            })
            .pipe(
              map((authData) => {
                logger.info('Login successful');
                return accountActions.fetchAccount({authData});
              }),
              catchError((error) => {
                httpErrorHandler.handleError(error);
                return of(accountActions.loginFailure());
              }),
            );
        }),
      );
    },
  );

  autoLoginEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      storage = inject(StorageService),
      logger = inject(NGXLogger),
    ) => {
      return actions$.pipe(
        ofType(accountActions.autoLogin),
        switchMap(() => {
          logger.info(`Starting auto login`);
          return from(
            storage.get(EStorageKey.AUTHDATA).then((val) => {
              return val;
            }),
          );
        }),
        switchMap((authData) => {
          if (!authData) {
            logger.info(`No auth data found in locale storage`);
            return of(accountActions.loginFailure());
          }
          logger.info(`Auth data found in local storage`);
          return of(accountActions.fetchAccount({authData}));
        }),
      );
    },
  );

  fetchAccountEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      accountService = inject(AccountService),
      logger = inject(NGXLogger),
      httpErrorHandler = inject(HttpErrorHandlerService),
    ) => {
      return actions$.pipe(
        ofType(accountActions.fetchAccount),
        switchMap(() => {
          return accountService.getUserData().pipe(
            map((account) => {
              logger.info('User data fetched');
              return accountActions.loginSuccess({account});
            }),
            catchError((error) => {
              logger.error('Failed to fetch user data');
              httpErrorHandler.handleError(error);
              return of(accountActions.loginFailure());
            }),
          );
        }),
      );
    },
  );

  loginSuceessEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      logger = inject(NGXLogger),
      navCtrl = inject(NavController),
      store = inject(Store),
      translateService = inject(TranslateService),
      flowControlService = inject(FlowControlService),
    ) => {
      return actions$.pipe(
        ofType(accountActions.loginSuccess),
        withLatestFrom(store.select(selectAccount)),
        map(([action, account]) => {
          logger.info('Login success');
          if (account)
            flowControlService.setCurrentAccountForEmployeeState(account);
          if (account?.passwordReset === EPasswordReset.DEFAULT) {
            navCtrl.navigateRoot([EAppRoutes.PASSWORD_RESET]);
            store.dispatch(
              alertActions.showAlert({
                message: translateService.instant(
                  'CORE.MESSAGES.UPDATE_PASSWORD_NOTE',
                ),
                messageType: EMessageType.INFORMATION,
              }),
            );
          } else {
            navCtrl.navigateRoot(['/']);
          }
        }),
        delay(500),
        map(() => splashScreenActions.hide()),
      );
    },
  );

  loginFailedEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      logger = inject(NGXLogger),
      navCtrl = inject(NavController),
    ) => {
      return actions$.pipe(
        ofType(accountActions.loginFailure),
        map(() => {
          logger.error('Login failed');
          navCtrl.navigateRoot([EAppRoutes.AUTH]);
        }),
        delay(500),
        map(() => splashScreenActions.hide()),
      );
    },
  );

  resetPasswordEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      accountService = inject(AccountService),
      logger = inject(NGXLogger),
      httpErrorHandler = inject(HttpErrorHandlerService),
    ) => {
      return actions$.pipe(
        ofType(accountActions.resetPassword),
        switchMap((action) => {
          return accountService.resetPassword(action.email).pipe(
            map((authData) => {
              logger.info('Reset password success');
              return accountActions.resetPasswordSuccess();
            }),
            catchError((error) => {
              httpErrorHandler.handleError(error);
              return of();
            }),
          );
        }),
      );
    },
  );

  resetPasswordSuccessEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      translateService = inject(TranslateService),
    ) => {
      return actions$.pipe(
        ofType(accountActions.resetPasswordSuccess),
        map(() => {
          return alertActions.showAlert({
            message: translateService.instant(
              'CORE.MESSAGES.NEW_PASSWORD_SEND',
            ),
            messageType: EMessageType.INFORMATION,
          });
        }),
      );
    },
  );

  updatePasswordEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      accountService = inject(AccountService),
      logger = inject(NGXLogger),
      store = inject(Store),
    ) => {
      return actions$.pipe(
        ofType(accountActions.updatePassword),
        withLatestFrom(store.select(selectAccount)),
        switchMap(([action, account]) => {
          return accountService
            .updatePassword(
              account?.email!,
              action.password,
              action.password_confirmation,
            )
            .pipe(
              map((authData) => {
                logger.info('Update password success');
                return accountActions.updatePasswordLogin({
                  username: account?.email!,
                  password: action.password,
                });
              }),
              catchError((error) => {
                return of(accountActions.updatePasswordFailure());
              }),
            );
        }),
      );
    },
  );

  updatePasswordLoginEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      accountService = inject(AccountService),
      logger = inject(NGXLogger),
    ) => {
      return actions$.pipe(
        ofType(accountActions.updatePasswordLogin),
        switchMap((action) => {
          return accountService
            .login({
              username: action.username,
              password: action.password,
            })
            .pipe(
              map((authData) => {
                logger.info('Login after password update successful');
                return accountActions.updatePasswordFetchAccount({authData});
              }),
              catchError((error) => {
                return of(accountActions.updatePasswordFailure());
              }),
            );
        }),
      );
    },
  );

  updateLoginFetchAccountEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      accountService = inject(AccountService),
      logger = inject(NGXLogger),
    ) => {
      return actions$.pipe(
        ofType(accountActions.updatePasswordFetchAccount),
        switchMap(() => {
          return accountService.getUserData().pipe(
            map((account) => {
              logger.info('User data fetched after password update');
              return accountActions.updatePasswordSuccess({account});
            }),
            catchError((error) => {
              logger.error('Failed to fetch user data after password update');
              return of(accountActions.updatePasswordFailure());
            }),
          );
        }),
      );
    },
  );

  updatePasswordSuccessEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      translateService = inject(TranslateService),
      navCtrl = inject(NavController),
    ) => {
      return actions$.pipe(
        ofType(accountActions.updatePasswordSuccess),
        map(() => {
          navCtrl.navigateRoot([EAppRoutes.HOME]);
          return alertActions.showAlert({
            message: translateService.instant('CORE.MESSAGES.PASSWORD_UPDATED'),
            messageType: EMessageType.INFORMATION,
          });
        }),
      );
    },
  );

  updatePasswordFailedEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      logger = inject(NGXLogger),
      navCtrl = inject(NavController),
      store = inject(Store),
      translateService = inject(TranslateService),
    ) => {
      return actions$.pipe(
        ofType(accountActions.updatePasswordFailure),
        map(() => {
          logger.error('Update password failed. Redirecting to login page');
          navCtrl.navigateRoot([EAppRoutes.AUTH]);
          store.dispatch(
            alertActions.showAlert({
              message: translateService.instant(
                'CORE.MESSAGES.PASSWORD_CHANGE_FAILED',
              ),
              messageType: EMessageType.INFORMATION,
            }),
          );
          return accountActions.deleteAuthData();
        }),
      );
    },
  );

  updateAccountEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      accountService = inject(AccountService),
      logger = inject(NGXLogger),
      httpErrorHandler = inject(HttpErrorHandlerService),
    ) => {
      return actions$.pipe(
        ofType(accountActions.updateAccount),
        switchMap((action) => {
          return accountService.updateUser(action.account).pipe(
            map((user) => {
              logger.info('Update user success');
              return accountActions.updateAccountSuccess({account: user});
            }),
            catchError((error) => {
              httpErrorHandler.handleError(error);
              return of(accountActions.updateAccountFailure());
            }),
          );
        }),
      );
    },
  );

  updateAccountSuccessEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      translateService = inject(TranslateService),
      navCtrl = inject(NavController),
    ) => {
      return actions$.pipe(
        ofType(accountActions.updateAccountSuccess),
        map(() => {
          navCtrl.navigateBack([EAppRoutes.ACCOUNT], {animated: false});
          return alertActions.showAlert({
            message: translateService.instant(
              'CORE.MESSAGES.USER_DATA_UPDATED',
            ),
            messageType: EMessageType.INFORMATION,
          });
        }),
      );
    },
  );

  updateAccountFailedEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),

      logger = inject(NGXLogger),
    ) => {
      return actions$.pipe(
        ofType(accountActions.updateAccountFailure),
        tap(() => {
          logger.info(`Update account failed`);
          navCtrl.navigateBack([EAppRoutes.ACCOUNT], {animated: false});
        }),
      );
    },
    {dispatch: false},
  );

  startLogoutEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      accountService = inject(AccountService),
      logger = inject(NGXLogger),
      httpErrorHandler = inject(HttpErrorHandlerService),
    ) => {
      return actions$.pipe(
        ofType(accountActions.startLogout),
        tap(() => {
          logger.info(`Logging out user from app`);
        }),
        switchMap(() => {
          return accountService.logout().pipe(
            map(() => {
              return accountActions.logoutSuccess();
            }),
            catchError((error) => {
              httpErrorHandler.handleError(error);
              return of(accountActions.loginFailure());
            }),
          );
        }),
      );
    },
  );

  logoutSuccessEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      storage = inject(StorageService),
      logger = inject(NGXLogger),
      navCtrl = inject(NavController),
    ) => {
      return actions$.pipe(
        ofType(accountActions.logoutSuccess),
        map((result) => {
          logger.info('Logout success');
          navCtrl.navigateRoot([EAppRoutes.AUTH]);
          return accountActions.deleteAuthData();
        }),
      );
    },
  );

  sessionExpiredEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      logger = inject(NGXLogger),
      navCtrl = inject(NavController),
    ) => {
      return actions$.pipe(
        ofType(accountActions.sessionExpired),
        map(() => {
          logger.error('Session expired. Redirecting to login page');
          navCtrl.navigateRoot([EAppRoutes.AUTH]);
          return accountActions.deleteAuthData();
        }),
      );
    },
  );

  deleteAuthDataEffect = createEffect(
    (
      actions$ = inject(Actions),
      storage = inject(StorageService),
      logger = inject(NGXLogger),
      flowControlService = inject(FlowControlService),
    ) => {
      return actions$.pipe(
        ofType(accountActions.deleteAuthData),
        tap(() => {
          flowControlService.logout();
        }),
        switchMap(() => {
          return from(
            storage
              .remove(EStorageKey.AUTHDATA)
              .then((val) => {
                return true;
              })
              .catch((error) => {
                logger.error(`Failed to remove auth data from local storage`);
                return false;
              }),
          );
        }),
      );
    },
    {dispatch: false},
  );
}
