import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {IAbsence} from '../../types/absence.interface';
import {EAbsenceStatus, EAbsencesType} from '../../enums/absences.enum';
import {IAbsenceFormSubmitValues} from 'src/app/features/employees/components/absence-form/types/absence-form-submit-values.interface';

export const absenceActions = createActionGroup({
  source: 'absence',
  events: {
    SetAbsence: props<{payload: IAbsence | null}>(),
    SetOpenAbsence: props<{payload: IAbsence | null}>(),
    SetAbsenceType: props<{payload: EAbsencesType | null}>(),
    SetDate: props<{payload: {startDate: Date | null; endDate: Date | null}}>(),
    AddAbsence: props<{payload: IAbsenceFormSubmitValues}>(),
    AddAbsenceSuccess: emptyProps(),
    AddAbsenceFailure: emptyProps(),
    UpdateAbsence: props<{payload: IAbsenceFormSubmitValues}>(),
    UpdateAbsenceSuccess: emptyProps(),
    UpdateAbsenceFailure: emptyProps(),
    UpdateAbsenceStatus: props<{
      payload: IAbsence;
    }>(),
    UpdateAbsenceStatusSuccess: emptyProps(),
    ApproveAbsenceCard: props<{payload: IAbsenceFormSubmitValues}>(),
    ApproveAbsenceCardSuccess: emptyProps(),
    DeleteAbsence: props<{payload: number}>(),
    DeleteAbsenceSuccess: emptyProps(),
    DeleteAbsenceCard: props<{payload: number}>(),
    DeleteAbsenceCardSuccess: emptyProps(),
    DeleteAbsenceFailure: emptyProps(),
    StopLoading: emptyProps(),
    Reset: emptyProps(),
  },
});
