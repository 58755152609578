import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {ITimeResponse} from './types/time-response.interface';

export const timeActions = createActionGroup({
  source: 'SystemTime',
  events: {
    'Fetch time': emptyProps,
    'Fetch time success': props<{date: ITimeResponse}>(),
    'Failed fetching': emptyProps,
    'Set custom time': props<{unixDate: number}>(),
    'Set custom time diff': props<{timeDiff: number}>(),
    'Set custom success': emptyProps,
    'Update time': emptyProps,
    'Use custom time': props<{useCustomTime: boolean}>(),
    'Start intervall': props<{delay: number}>(),
    'Start clock intervall': emptyProps,
    'Set server time': props<{date: Date}>(),
    'Set server clock time': props<{date: Date}>(),
    'Set time after custom time update': props<{date: ITimeResponse}>(),
    'Reset custom time': emptyProps,
  },
});
