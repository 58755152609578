import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  private get logger() {
    return this.injector.get(NGXLogger);
  }

  constructor(private injector: Injector) {}
  handleError(error: any): void {
    this.logger.error(error);
  }
}
