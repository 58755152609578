import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {workRecordActions} from './actions';
import {selectTimeStateSystemTime} from 'src/app/shared/store/time/selectors/time.selectors';
import {catchError, delay, map, of, switchMap, withLatestFrom} from 'rxjs';
import {WorkRecordService} from './services/work-record.service';
import {IWorkRecordStartEndTime} from '../../../features/employees/components/work-record-form/types/workRecordStartEndTime.interface';
import {
  selectEmployeeStateCoreEndTime,
  selectEmployeeStateCoreStartTime,
  selectEmployeeStateStartedWorkRecordForCurrentDay,
} from 'src/app/shared/store/employees/selectors/employees.selectors';
import {employeeActions} from 'src/app/shared/store/employees/actions';
import {IEmployeesDataState} from 'src/app/shared/store/employees/types/employees-data-state';
import {HttpErrorHandlerService} from 'src/app/shared/services/http-error-handler.service';
import {NavController} from '@ionic/angular/standalone';
import {
  selectAliasView,
  selectCurrentEmployeeID,
  selectEmployeesState,
} from 'src/app/shared/store/employees/reducer';
import {selectAccount} from 'src/app/shared/store/account/reducer';
import {IWorkRecord} from 'src/app/shared/types/work-record.interface';
import {
  EWorkRecordNotified,
  EWorkRecordStatus,
} from 'src/app/shared/enums/workRecord-enums';
import {selectEmployeesStateIsAfterCoreEndTime} from 'src/app/shared/store/employees/selectors/hours-overview.component.selectors';

@Injectable()
export class WorkRecordsFeatureEffects {
  setStartEndTimes$ = createEffect(
    (
      actions$ = inject(Actions),
      store = inject(Store),
      workRecordService = inject(WorkRecordService),
    ) =>
      actions$.pipe(
        ofType(workRecordActions.setDate),
        withLatestFrom(
          store.select(selectTimeStateSystemTime),
          store.select(selectEmployeeStateCoreStartTime),
          store.select(selectEmployeeStateCoreEndTime),
        ),
        delay(10),
        map(([action, startTime, coreStartTime, coreEndTime]) => {
          let startEndTimes: IWorkRecordStartEndTime =
            workRecordService.calculateStartEndTimes(
              startTime,
              action.payload,
              coreStartTime,
              coreEndTime,
            );
          return workRecordActions.setStartEndTimes({payload: startEndTimes});
        }),
      ),
  );

  newWorkRecord$ = createEffect(
    (
      actions$ = inject(Actions),
      workRecordService = inject(WorkRecordService),
      httpErrorHandler = inject(HttpErrorHandlerService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workRecordActions.addWorkRecord),
        withLatestFrom(
          store.select(selectCurrentEmployeeID),
          store.select(selectAliasView),
          store.select(selectAccount),
        ),
        switchMap(([action, currentEmployee, alias, account]) => {
          const workrecord: IWorkRecord = {
            id: null,
            employee_id: currentEmployee!,
            editor_id: alias ? account?.id : null,
            start_time: action.payload.startTime!,
            end_time: action.payload.endTime,
            comment: action.payload.comment,
            comment_editor: action.payload.commentEditor,
            notified: EWorkRecordNotified.NOT_NOTIFIED,
            status: alias
              ? EWorkRecordStatus.CREATED_BY_EDITOR
              : EWorkRecordStatus.CREATED_BY_EMPLOYEE_MANUALLY,
            editor: null,
          };

          return workRecordService.addNewWorkRecord(workrecord).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return workRecordActions.addWorkRecordSuccess();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(workRecordActions.addWorkRecordFailure());
            }),
          );
        }),
      ),
  );

  updateWorkRecord$ = createEffect(
    (
      actions$ = inject(Actions),
      workRecordService = inject(WorkRecordService),
      httpErrorHandler = inject(HttpErrorHandlerService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workRecordActions.updateWorkRecord),
        withLatestFrom(
          store.select(selectCurrentEmployeeID),
          store.select(selectAliasView),
          store.select(selectAccount),
        ),
        switchMap(([action, currentEmployee, alias, account]) => {
          const workrecord: IWorkRecord = {
            id: action.workrecord.id,
            employee_id: currentEmployee!,
            editor_id: alias ? account?.id : null,
            start_time: action.values.startTime!,
            end_time: action.values.endTime,
            comment: action.values.comment,
            comment_editor: action.values.commentEditor,
            notified: EWorkRecordNotified.NOT_NOTIFIED,
            status: alias
              ? EWorkRecordStatus.UPDATED_BY_EDITOR
              : EWorkRecordStatus.UPDATED_BY_EMPLOYEE,
            editor: null,
          };

          return workRecordService.updateWorkRecord(workrecord).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return workRecordActions.updateWorkRecordSuccess();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(workRecordActions.updateWorkRecordFailure());
            }),
          );
        }),
      ),
  );

  stempNewWorkRecord$ = createEffect(
    (
      httpErrorHandler = inject(HttpErrorHandlerService),
      actions$ = inject(Actions),
      store = inject(Store),
      workRecordService = inject(WorkRecordService),
    ) =>
      actions$.pipe(
        ofType(workRecordActions.stempNewWorkRecord),
        withLatestFrom(
          store.select(selectEmployeesState),
          store.select(selectTimeStateSystemTime),
        ),
        switchMap(([action, employeeState, systemTime]) => {
          const workrecord: IWorkRecord = {
            id: null,
            employee_id: employeeState.currentEmployee!,
            editor_id: null,
            start_time: new Date(systemTime),
            end_time: null,
            comment: '',
            comment_editor: '',
            notified: EWorkRecordNotified.NOTIFIED,
            status: EWorkRecordStatus.CREATED_BY_EMPLOYEE_AUTOMATICALLY,
            editor: null,
          };

          return workRecordService.addNewWorkRecord(workrecord).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return workRecordActions.stopLoading();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(employeeActions.stopLoading());
            }),
          );
        }),
      ),
  );

  stempOutWorkRecord$ = createEffect(
    (
      httpErrorHandler = inject(HttpErrorHandlerService),
      actions$ = inject(Actions),
      store = inject(Store),
      workRecordService = inject(WorkRecordService),
    ) =>
      actions$.pipe(
        ofType(workRecordActions.stempOutWorkRecord),
        withLatestFrom(
          store.select(selectTimeStateSystemTime),
          store.select(selectEmployeeStateStartedWorkRecordForCurrentDay),
          store.select(selectEmployeesStateIsAfterCoreEndTime),
          store.select(selectEmployeeStateCoreEndTime),
        ),
        switchMap(
          ([action, systemTime, workRecord, isAfterEndTime, coreEndTime]) => {
            const workrecord: IWorkRecord = {
              id: workRecord!.id,
              employee_id: workRecord!.employee_id,
              editor_id: workRecord!.editor_id,
              start_time: new Date(workRecord!.start_time),
              end_time: isAfterEndTime ? coreEndTime : systemTime,
              comment: workRecord!.comment,
              comment_editor: workRecord!.comment_editor,
              notified: EWorkRecordNotified.NOTIFIED,
              status: EWorkRecordStatus.CREATED_BY_EMPLOYEE_AUTOMATICALLY,
              editor: null,
            };

            return workRecordService.updateWorkRecord(workrecord).pipe(
              map((response: IEmployeesDataState) => {
                store.dispatch(
                  employeeActions.successFetchingEmployees({
                    payload: response,
                  }),
                );
                return workRecordActions.stopLoading();
              }),
              catchError((error: any) => {
                httpErrorHandler.handleError(error);
                return of(employeeActions.stopLoading());
              }),
            );
          },
        ),
      ),
  );

  deleteWorkRecordFromCard$ = createEffect(
    (
      httpErrorHandler = inject(HttpErrorHandlerService),
      actions$ = inject(Actions),
      workRecordService = inject(WorkRecordService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workRecordActions.deleteWorkRecordFromCard),
        switchMap((action) => {
          return workRecordService.deleteWorkRecord(action.payload).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return workRecordActions.stopLoading();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(employeeActions.stopLoading());
            }),
          );
        }),
      ),
  );

  deleteWorkRecord$ = createEffect(
    (
      httpErrorHandler = inject(HttpErrorHandlerService),
      actions$ = inject(Actions),
      workRecordService = inject(WorkRecordService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workRecordActions.deleteWorkRecord),
        switchMap((action) => {
          return workRecordService.deleteWorkRecord(action.payload).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return workRecordActions.deleteWorkRecordSuccess();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(employeeActions.stopLoading());
            }),
          );
        }),
      ),
  );

  newWorkRecordSuccess$ = createEffect(
    (actions$ = inject(Actions), navCtrl = inject(NavController)) =>
      actions$.pipe(
        ofType(workRecordActions.addWorkRecordSuccess),
        map(() => {
          navCtrl.back();
        }),
      ),
    {dispatch: false},
  );

  updateWorkRecordSuccess$ = createEffect(
    (actions$ = inject(Actions), navCtrl = inject(NavController)) =>
      actions$.pipe(
        ofType(workRecordActions.updateWorkRecordSuccess),
        map(() => {
          navCtrl.back();
        }),
      ),
    {dispatch: false},
  );

  deletWorkRecordSuccess$ = createEffect(
    (actions$ = inject(Actions), navCtrl = inject(NavController)) =>
      actions$.pipe(
        ofType(workRecordActions.deleteWorkRecordSuccess),
        map(() => {
          navCtrl.back();
        }),
      ),
    {dispatch: false},
  );
}
