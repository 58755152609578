import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage, private logger: NGXLogger) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  async get(key: string) {
    return this.init().then(() => {
      return this._storage
        ?.get(key)
        .then((val) => {
          return JSON.parse(val);
        })
        .catch((error) => {
          console.error(`Could not get value of Key ${key} from storage.`);
        });
    });
  }

  async getKeys() {
    await this._storage?.keys();
  }

  // Create and expose methods that users of this service can
  async set(key: string, value: any) {
    await this.init().then(() => {
      return this._storage
        ?.set(key, JSON.stringify(value))
        .then((val) => {})
        .catch((error) => {
          console.error(`Could not save value of Key ${key} to storage.`);
        });
    });
  }

  async remove(key: string) {
    await this._storage
      ?.remove(key)
      .then(() => {
        this.logger.info(`Removed values of Key ${key} from storage.`);
      })
      .catch((error) => {
        console.error(`Could not remove values of Key ${key} from storage.`);
      });
  }

  async clear() {
    await this._storage
      ?.clear()
      .then(() => {
        this.logger.info(`App storage cleared!`);
      })
      .catch((error) => {
        console.error(`Could not clear App storage.`);
      });
  }
}
