import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  catchError,
  delay,
  interval,
  map,
  of,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {timeActions} from './actions';
import {TimeService} from './services/time.service';
import {Store} from '@ngrx/store';
import {
  addMinutes,
  addSeconds,
  getSeconds,
  getUnixTime,
  setSeconds,
} from 'date-fns';
import {
  selectCustomTimeDiff,
  selectServerClock,
  selectServerTime,
} from './reducer';
import {ITimeResponse} from './types/time-response.interface';
import {EMessageType} from '../../enums/message-type.enum';
import {alertActions} from 'src/app/features/alert/store/actions';

@Injectable()
export class TimeFeatureEffects {
  fetchTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.fetchTime),
      switchMap(() => {
        return this.timeService.fetchSystemTime().pipe(
          map((systemTime: ITimeResponse) => {
            return timeActions.fetchTimeSuccess({date: systemTime});
          }),
          catchError((error: any) => {
            return of(
              alertActions.showAlert({
                message: error.message,
                messageType: EMessageType.ERROR,
              }),
            );
          }),
        );
      }),
    ),
  );

  setCustomTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.setCustomTime),
      withLatestFrom(this.store.select(selectServerTime)),
      map(([action, serverTime]) => {
        let timeDiff = this.timeService.getCustomTimeDiff(
          action.unixDate,
          getUnixTime(serverTime),
        );
        return timeActions.setCustomTimeDiff({timeDiff: timeDiff});
      }),
    ),
  );

  setCustomTimeDiff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.setCustomTimeDiff),
      map((action) => {
        return timeActions.setCustomSuccess();
      }),
    ),
  );

  resetCustomTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.resetCustomTime),
      map((action) => {
        return timeActions.setCustomSuccess();
      }),
    ),
  );

  setCustomTimeSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.setCustomSuccess),
      switchMap((action) =>
        of(action).pipe(
          delay(1500),
          withLatestFrom(this.store.select(selectCustomTimeDiff)),
          switchMap(([action, latestUpdate]) => {
            return this.timeService.updateServerCustomTimeOn(latestUpdate).pipe(
              map((systemTime: ITimeResponse) => {
                return timeActions.setTimeAfterCustomTimeUpdate({
                  date: systemTime,
                });
              }),
              catchError((error: any) => {
                return of(
                  alertActions.showAlert({
                    message: error.message,
                    messageType: EMessageType.ERROR,
                  }),
                );
              }),
            );
          }),
        ),
      ),
    ),
  );

  fetchTimeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.fetchTimeSuccess),
      map((action) => {
        let delayStart = 60 - getSeconds(action.date.systemTime);
        let delayInMilliseconds = delayStart > 0 ? delayStart * 1000 : 0;
        return delayInMilliseconds;
      }),
      switchMap((delayInMilliseconds) =>
        of(timeActions.startIntervall({delay: delayInMilliseconds})),
      ),
    ),
  );

  fetchSetCustomTimeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.setTimeAfterCustomTimeUpdate),
      map((action) => {
        let delayStart = 60 - getSeconds(action.date.systemTime);
        let delayInMilliseconds = delayStart > 0 ? delayStart * 1000 : 0;
        return delayInMilliseconds;
      }),
      switchMap((delayInMilliseconds) =>
        of(timeActions.startIntervall({delay: delayInMilliseconds})),
      ),
    ),
  );

  startIntervall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.startIntervall),
      switchMap((action) => {
        return of(null).pipe(
          tap(() => {
            this.store.dispatch(timeActions.startClockIntervall());
          }),
          delay(action.delay),
          switchMap(() =>
            interval(60000).pipe(
              startWith(0),
              withLatestFrom(this.store.select(selectServerTime)),
              map(([action, serverTime]) => {
                let date = addMinutes(serverTime, 1);
                date = setSeconds(date, 0);
                return timeActions.setServerTime({date});
              }),
            ),
          ),
        );
      }),
    ),
  );

  startClockIntervall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.startClockIntervall),
      switchMap((action) => {
        return of(null).pipe(
          switchMap(() =>
            interval(1000).pipe(
              startWith(0),
              withLatestFrom(this.store.select(selectServerClock)),
              map(([action, serverClock]) => {
                let date = addSeconds(serverClock, 1);
                return timeActions.setServerClockTime({date});
              }),
            ),
          ),
        );
      }),
    ),
  );
  constructor(
    private actions$: Actions,
    private timeService: TimeService,
    private store: Store,
  ) {}
}
