import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {IWorkRecordStartEndTime} from '../../../features/employees/components/work-record-form/types/workRecordStartEndTime.interface';
import {IWorkRecord} from 'src/app/shared/types/work-record.interface';
import {IWorkRecordFormValues} from '../../../features/employees/components/work-record-form/types/work-record-form-values.interface';
import {Reset} from '@ngrx/store-devtools/src/actions';

export const workRecordActions = createActionGroup({
  source: 'WorkRecord',
  events: {
    EditWorkRecord: props<{payload: number}>(),
    ResetEditWorkRecord: emptyProps(),
    SetDate: props<{payload: Date | null}>(),
    SetStartEndTimes: props<{payload: IWorkRecordStartEndTime}>(),
    AddWorkRecord: props<{payload: IWorkRecordFormValues}>(),
    AddWorkRecordSuccess: emptyProps(),
    AddWorkRecordFailure: emptyProps(),
    UpdateWorkRecord: props<{
      workrecord: IWorkRecord;
      values: IWorkRecordFormValues;
    }>(),
    UpdateWorkRecordSuccess: emptyProps(),
    UpdateWorkRecordFailure: emptyProps(),
    StempNewWorkRecord: emptyProps,
    StempOutWorkRecord: emptyProps,
    DeleteWorkRecord: props<{payload: number}>(),
    DeleteWorkRecordSuccess: emptyProps(),
    DeleteWorkRecordFailed: emptyProps(),
    DeleteWorkRecordFromCard: props<{payload: number}>(),
    StopLoading: emptyProps(),
  },
});
