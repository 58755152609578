import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ILoginRequest} from '../types/login-request.interface';
import {map, Observable} from 'rxjs';
import {IAuthData} from 'src/app/shared/types/auth-data.interface';
import {environment} from 'src/environments/environment';
import {ILoginResponse} from '../types/login-response.interface';
import {Store} from '@ngrx/store';
import {StorageService} from 'src/app/shared/services/storage.service';
import {EStorageKey} from 'src/app/shared/enums/storage-key.enum';
import {IUser} from 'src/app/shared/types/user.interface';
import {selectServerClock} from '../../time/reducer';
import {convertUTCDateTimeToLocal} from 'src/app/shared/utils/util.functions';
import {IAccountFormValues} from 'src/app/features/account/components/account-form/types/account-form-values.interface';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  http = inject(HttpClient);
  storage = inject(StorageService);
  store = inject(Store);
  url = environment.apiUrl;
  serverTime: Date = new Date();

  constructor() {
    this.store.select(selectServerClock).subscribe((serverTime) => {
      this.serverTime = serverTime;
    });
  }

  login(data: {username: string; password: string}): Observable<IAuthData> {
    const REQUEST: ILoginRequest = {
      grant_type: 'password',
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      username: data.username,
      password: data.password,
    };
    return this.http
      .post(this.url + 'oauth/token', REQUEST, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          const loginResponse: ILoginResponse = response.body as ILoginResponse;
          return this.saveAuthData(loginResponse, this.serverTime);
        }),
      );
  }

  getUserData(): Observable<IUser> {
    return this.http
      .get(this.url + 'users/me', {
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          const userData = response.body.data;
          const transformedUserData: IUser = {
            ...userData,
            entryDate: userData.entryDate
              ? convertUTCDateTimeToLocal(userData.entryDate)
              : userData.entryDate,
            exitDate: userData.exitDate
              ? convertUTCDateTimeToLocal(userData.exitDate)
              : userData.exitDate,
          };
          return transformedUserData;
        }),
      );
  }

  refreshAuthData(authData: IAuthData): Observable<IAuthData> {
    const REQUEST = {
      grant_type: 'refresh_token',
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      refresh_token: authData.refresh_token,
    };
    return this.http
      .post(this.url + 'oauth/token', REQUEST, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          const loginResponse: ILoginResponse = response.body as ILoginResponse;
          return this.saveAuthData(loginResponse, this.serverTime);
        }),
      );
  }

  resetPassword(email: string): Observable<string> {
    return this.http
      .post<any>(
        environment.apiUrl + 'users/password/reset',
        {
          email,
        },
        {observe: 'response'},
      )
      .pipe(
        map((response) => {
          return response.body;
        }),
      );
  }

  updatePassword(
    email: string,
    password: string,
    password_confirmation: string,
  ): Observable<string> {
    return this.http
      .post<any>(
        environment.apiUrl + 'users/password/new',
        {
          email,
          password,
          password_confirmation,
        },
        {observe: 'response'},
      )
      .pipe(
        map((response) => {
          return response.body;
        }),
      );
  }

  updateUser(data: IAccountFormValues): Observable<IUser> {
    return this.http
      .patch<any>(
        environment.apiUrl + 'users/me/edit',
        {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          address: data.address,
          houseNr: data.houseNr,
          zipCode: data.zipCode,
          city: data.city,
        },
        {observe: 'response'},
      )
      .pipe(
        map((response) => {
          const userData = response.body.data;
          const transformedUserData: IUser = {
            ...userData,
            entryDate: userData.entryDate
              ? convertUTCDateTimeToLocal(userData.entryDate)
              : userData.entryDate,
            exitDate: userData.exitDate
              ? convertUTCDateTimeToLocal(userData.exitDate)
              : userData.exitDate,
          };
          return transformedUserData;
        }),
      );
  }

  logout(): Observable<string> {
    return this.http
      .post<any>(this.url + 'users/logout', {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  saveAuthData(response: ILoginResponse, serverTime: Date): IAuthData {
    const authData: IAuthData = {
      token_type: response.token_type,
      expires_in: response.expires_in,
      access_token: response.access_token,
      refresh_token: response.refresh_token,
      token_expiration_date: new Date(
        serverTime.getTime() + response.expires_in * 1000,
      ),
    };
    this.storage.set(EStorageKey.AUTHDATA, authData);
    return authData;
  }
}
