@if (data$ | async; as data) {
  @if (data.isVisible) {
    <div class="wrapper">
      <div class="wrapper-inner">
        <div class="app-logo fade-in">
          <img alt="" src="../../../../assets/images/baerenkinder_logo.png" />
        </div>
        <div class="app-label">
          <h1>{{ appName }}</h1>
        </div>
        <ion-spinner color="light" name="dots"></ion-spinner>
      </div>
    </div>
  }
}
