import {createFeature, createReducer, on} from '@ngrx/store';
import {IWorkRecordState} from './types/work-record-state.interface';
import {workRecordActions} from './actions';

const initialState: IWorkRecordState = {
  editWorkRecordId: null,
  date: null,
  startEndTimes: {
    minStartTime: null,
    maxStartTime: null,
    minEndTime: null,
    maxEndTime: null,
    defaultStartTime: {
      hour: 7,
      minute: 0,
      second: 0,
    },
  },
  isLoading: false,
  isLoadingCard: false,
};

const workRecordFeature = createFeature({
  name: 'workRecord',
  reducer: createReducer(
    initialState,
    on(workRecordActions.editWorkRecord, (state, action) => ({
      ...state,
      editWorkRecordId: action.payload,
    })),
    on(workRecordActions.resetEditWorkRecord, (state) => ({
      ...state,
      editWorkRecordId: null,
    })),
    on(workRecordActions.setDate, (state, action) => ({
      ...state,
      date: action.payload,
    })),
    on(workRecordActions.setStartEndTimes, (state, action) => ({
      ...state,
      startEndTimes: action.payload,
    })),
    on(workRecordActions.stempNewWorkRecord, (state) => ({
      ...state,
      isLoadingCard: true,
    })),
    on(workRecordActions.stempOutWorkRecord, (state) => ({
      ...state,
      isLoadingCard: true,
    })),
    on(workRecordActions.addWorkRecord, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(workRecordActions.addWorkRecordSuccess, (state) => ({
      ...state,
      isLoading: false,
    })),
    on(workRecordActions.addWorkRecordFailure, (state) => ({
      ...state,
      isLoading: false,
    })),
    on(workRecordActions.updateWorkRecord, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(workRecordActions.updateWorkRecordSuccess, (state) => ({
      ...state,
      isLoading: false,
      editWorkRecordId: null,
    })),
    on(workRecordActions.updateWorkRecordFailure, (state) => ({
      ...state,
      isLoading: false,
    })),
    on(workRecordActions.deleteWorkRecord, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(workRecordActions.deleteWorkRecordSuccess, (state) => ({
      ...state,
      isLoading: false,
      editWorkRecordId: null,
    })),
    on(workRecordActions.deleteWorkRecordFailed, (state) => ({
      ...state,
      isLoading: false,
    })),
    on(workRecordActions.deleteWorkRecordFromCard, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(workRecordActions.stopLoading, (state) => ({
      ...state,
      isLoading: false,
      isLoadingCard: false,
    })),
  ),
});

export const {
  name: workRecordFeatureKey,
  reducer: workRecordReducer,
  selectEditWorkRecordId: selectWorkRecordEditWorkRecordId,
  selectDate: selectWorkRecordDate,
  selectIsLoading: selectWorkRecordIsLoading,
  selectIsLoadingCard: selectWorkRecordIsLoadingCard,
  selectStartEndTimes: selectWorkRecordStartEndTimes,
} = workRecordFeature;
