import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, of, switchMap} from 'rxjs';

import {employeeActions} from 'src/app/shared/store/employees/actions';
import {IEmployeesDataState} from 'src/app/shared/store/employees/types/employees-data-state';
import {HttpErrorHandlerService} from 'src/app/shared/services/http-error-handler.service';
import {NavController} from '@ionic/angular/standalone';
import {TranslateService} from '@ngx-translate/core';
import {alertActions} from 'src/app/features/alert/store/actions';
import {EMessageType} from '../../enums/message-type.enum';
import {EAppRoutes} from '../../enums/app-routes.enum';
import {absenceActions} from './actions';
import {AbsenceService} from './services/absence.service';

@Injectable()
export class AbsenceFeatureEffects {
  setAbsence$ = createEffect(
    (actions$ = inject(Actions), navCtrl = inject(NavController)) =>
      actions$.pipe(
        ofType(absenceActions.setAbsence),
        map((action) => {
          if (action.payload != null) {
            navCtrl.navigateForward([EAppRoutes.EDIT_ABSENCE]);
          }
        }),
      ),
    {dispatch: false},
  );

  setOpenAbsence$ = createEffect(
    (actions$ = inject(Actions), navCtrl = inject(NavController)) =>
      actions$.pipe(
        ofType(absenceActions.setOpenAbsence),
        map((action) => {
          if (action.payload != null) {
            navCtrl.navigateForward([EAppRoutes.STAFF_OPEN_ABSENCE]);
          }
        }),
      ),
    {dispatch: false},
  );

  setAbsenceType$ = createEffect(
    (actions$ = inject(Actions), navCtrl = inject(NavController)) =>
      actions$.pipe(
        ofType(absenceActions.setAbsenceType),
        map((action) => {
          if (action.payload != null) {
            navCtrl.navigateForward([EAppRoutes.NEW_ABSENCE]);
          }
        }),
      ),
    {dispatch: false},
  );
  newAbsence$ = createEffect(
    (
      actions$ = inject(Actions),
      absenceService = inject(AbsenceService),
      httpErrorHandler = inject(HttpErrorHandlerService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(absenceActions.addAbsence),
        switchMap((action) => {
          return absenceService.addNewAbsence(action.payload).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return absenceActions.addAbsenceSuccess();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(absenceActions.addAbsenceFailure());
            }),
          );
        }),
      ),
  );
  newAbsenceSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),
      translateService = inject(TranslateService),
    ) =>
      actions$.pipe(
        ofType(absenceActions.addAbsenceSuccess),
        map(() => {
          navCtrl.back();
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.ABSENT_RECORD.NEW_ABSENCE_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.ABSENT_RECORD.NEW_ABSENCE_SUCCESS',
          });
        }),
      ),
  );

  updateAbsence$ = createEffect(
    (
      actions$ = inject(Actions),
      absenceService = inject(AbsenceService),
      httpErrorHandler = inject(HttpErrorHandlerService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(absenceActions.updateAbsence),
        switchMap((action) => {
          return absenceService.updateAbsence(action.payload).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return absenceActions.updateAbsenceSuccess();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(absenceActions.updateAbsenceFailure());
            }),
          );
        }),
      ),
  );
  updateAbsenceSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),
      translateService = inject(TranslateService),
    ) =>
      actions$.pipe(
        ofType(absenceActions.updateAbsenceSuccess),
        map(() => {
          navCtrl.back();
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.ABSENT_RECORD.UPDATE_ABSENCE_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.ABSENT_RECORD.UPDATE_ABSENCE_SUCCESS',
          });
        }),
      ),
  );

  updateAbsenceStatus$ = createEffect(
    (
      actions$ = inject(Actions),
      absenceService = inject(AbsenceService),
      httpErrorHandler = inject(HttpErrorHandlerService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(absenceActions.updateAbsenceStatus),
        switchMap((action) => {
          return absenceService.updateAbsence(action.payload).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return absenceActions.updateAbsenceStatusSuccess();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(absenceActions.updateAbsenceFailure());
            }),
          );
        }),
      ),
  );
  updateAbsenceStatusSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),
      translateService = inject(TranslateService),
    ) =>
      actions$.pipe(
        ofType(absenceActions.updateAbsenceStatusSuccess),
        map(() => {
          navCtrl.back();
        }),
      ),
    {dispatch: false},
  );

  approveAbsenceCard$ = createEffect(
    (
      actions$ = inject(Actions),
      absenceService = inject(AbsenceService),
      httpErrorHandler = inject(HttpErrorHandlerService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(absenceActions.approveAbsenceCard),
        switchMap((action) => {
          return absenceService.updateAbsence(action.payload).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return absenceActions.approveAbsenceCardSuccess();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(absenceActions.updateAbsenceFailure());
            }),
          );
        }),
      ),
  );

  deleteAbsence$ = createEffect(
    (
      actions$ = inject(Actions),
      absenceService = inject(AbsenceService),
      httpErrorHandler = inject(HttpErrorHandlerService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(absenceActions.deleteAbsence),
        switchMap((action) => {
          return absenceService.deleteAbsence(action.payload).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return absenceActions.deleteAbsenceSuccess();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(absenceActions.deleteAbsenceFailure());
            }),
          );
        }),
      ),
  );
  deleteAbsenceSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),
      translateService = inject(TranslateService),
    ) =>
      actions$.pipe(
        ofType(absenceActions.deleteAbsenceSuccess),
        map(() => {
          navCtrl.back();
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.ABSENT_RECORD.DELETE_ABSENCE_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.ABSENT_RECORD.DELETE_ABSENCE_SUCCESS',
          });
        }),
      ),
  );

  deleteAbsenceCard$ = createEffect(
    (
      actions$ = inject(Actions),
      absenceService = inject(AbsenceService),
      httpErrorHandler = inject(HttpErrorHandlerService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(absenceActions.deleteAbsenceCard),
        switchMap((action) => {
          return absenceService.deleteAbsence(action.payload).pipe(
            map((response: IEmployeesDataState) => {
              store.dispatch(
                employeeActions.successFetchingEmployees({
                  payload: response,
                }),
              );
              return absenceActions.deleteAbsenceCardSuccess();
            }),
            catchError((error: any) => {
              httpErrorHandler.handleError(error);
              return of(absenceActions.deleteAbsenceFailure());
            }),
          );
        }),
      ),
  );
  deleteAbsenceCardSuccess$ = createEffect(
    (actions$ = inject(Actions), translateService = inject(TranslateService)) =>
      actions$.pipe(
        ofType(absenceActions.deleteAbsenceCardSuccess),
        map(() => {
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.ABSENT_RECORD.DELETE_ABSENCE_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.ABSENT_RECORD.DELETE_ABSENCE_SUCCESS',
          });
        }),
      ),
  );
}
