import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {map, Observable} from 'rxjs';
import {IUserFormValues} from 'src/app/features/users/components/user-form/types/user-form-values.interface';
import {IUser} from 'src/app/shared/types/user.interface';
import {convertUTCDateTimeToLocal} from 'src/app/shared/utils/util.functions';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  http = inject(HttpClient);
  url = environment.apiUrl;
  getUsers(): Observable<IUser[]> {
    return this.http
      .get(this.url + 'users', {
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          const userData = response.body.data;
          userData.forEach((user: any) => {
            user.entryDate = user.entryDate
              ? convertUTCDateTimeToLocal(user.entryDate)
              : user.entryDate;
            user.exitDate = user.exitDate
              ? convertUTCDateTimeToLocal(user.exitDate)
              : user.exitDate;
          });
          return userData;
        }),
      );
  }

  createUser(user: IUserFormValues): Observable<IUser> {
    return this.http
      .post<any>(
        environment.apiUrl + 'users',
        {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          gender: user.gender,
          role: user.role,
          phone: user.phone,
          address: user.address,
          houseNr: user.houseNr,
          zipCode: user.zipCode,
          city: user.city,
          entryDate: user.entryDate
            ? format(
                utcToZonedTime(user.entryDate, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          exitDate: user.exitDate
            ? format(
                utcToZonedTime(user.exitDate, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          status: user.status,
        },
        {observe: 'response'},
      )
      .pipe(
        map((response) => {
          const userData = response.body.data;
          const transformedUserData: IUser = {
            ...userData,
            entryDate: userData.entryDate
              ? convertUTCDateTimeToLocal(userData.entryDate)
              : userData.entryDate,
            exitDate: userData.exitDate
              ? convertUTCDateTimeToLocal(userData.exitDate)
              : userData.exitDate,
          };
          return transformedUserData;
        }),
      );
  }

  updateUser(id: number, user: IUserFormValues): Observable<IUser> {
    return this.http
      .patch<any>(
        environment.apiUrl + 'users/' + id,
        {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          role: user.role,
          phone: user.phone,
          address: user.address,
          houseNr: user.houseNr,
          zipCode: user.zipCode,
          city: user.city,
          entryDate: user.entryDate
            ? format(
                utcToZonedTime(user.entryDate, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          exitDate: user.exitDate
            ? format(
                utcToZonedTime(user.exitDate, 'UTC'),
                'yyyy-MM-dd HH:mm:ss',
              )
            : null,
          status: user.status,
        },
        {observe: 'response'},
      )
      .pipe(
        map((response) => {
          const userData = response.body.data;
          const transformedUserData: IUser = {
            ...userData,
            entryDate: userData.entryDate
              ? convertUTCDateTimeToLocal(userData.entryDate)
              : userData.entryDate,
            exitDate: userData.exitDate
              ? convertUTCDateTimeToLocal(userData.exitDate)
              : userData.exitDate,
          };
          return transformedUserData;
        }),
      );
  }

  deleteUser(id: number): Observable<any> {
    return this.http.delete(this.url + 'users/' + id, {
      observe: 'response',
    });
  }
}
