import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IEmployeesState} from '../types/employees-state.interface';
import {isAfter, isBefore, isSameDay} from 'date-fns';
import {selectTimeStateSystemTime} from '../../time/selectors/time.selectors';
import {
  selectAliasView,
  selectCurrentEmployeeID,
  selectEmployeesStateData,
} from '../reducer';
import {IWorkRecord} from '../../../types/work-record.interface';
import {IWorkTimeScheme} from '../../../types/work-time-scheme.interface';
import {IDayState} from '../types/day-state.interface';
import {IEmployeeDayState} from '../types/employee-day-state.interface';
import {selectSelectedUser} from '../../users/reducer';
import {IUser} from 'src/app/shared/types/user.interface';
import {IEmployeeState} from '../types/employee-state.interface';
import {IWorkingTimeRegulation} from '../types/work-time-regulation.interface';
import {ICarryOverRecord} from 'src/app/shared/types/carry-over-record.interface';
import {ca} from 'date-fns/locale';

export const selectEmployeesState =
  createFeatureSelector<IEmployeesState>('employees');
export const selectEmployeeStateCurrentMonth = createSelector(
  selectEmployeesState,
  selectTimeStateSystemTime,
  (state, systemTime) => {
    const {data} = state;
    if (!systemTime) {
      return null;
    }
    const currentYear = data.years.find(
      (year) => year.year === systemTime.getFullYear(),
    );
    if (!currentYear) {
      return null;
    }

    const currentMonth = currentYear.months.find(
      (month) => month.month === systemTime.getMonth() + 1,
    );
    if (!currentMonth) {
      return null;
    }

    return currentMonth;
  },
);

export const selectEmployeeStateCurrentDay = createSelector(
  selectEmployeesState,
  selectTimeStateSystemTime,
  (state, systemTime): IDayState | null => {
    const {data} = state;
    if (!systemTime || !data) {
      return null;
    }

    const currentYear = data.years.find(
      (year) => year.year === systemTime.getFullYear(),
    );
    if (!currentYear) {
      return null;
    }

    const currentMonth = currentYear.months.find(
      (month) => month.month === systemTime.getMonth() + 1,
    );
    if (!currentMonth) {
      return null;
    }

    const currentDay = currentMonth.days.find((day) =>
      isSameDay(new Date(day.date), systemTime),
    );
    if (!currentDay) {
      return null;
    }
    return currentDay;
  },
);

export const selectEmployeesStateCurrentDayStateForCurrentEmployee =
  createSelector(
    selectEmployeeStateCurrentDay,
    selectCurrentEmployeeID,
    (currentDay, currentEmployee): IEmployeeDayState | null => {
      if (!currentDay || !currentEmployee) {
        return null;
      }

      const employeeDayState = currentDay.employees.find(
        (employee) => employee.employeeId === currentEmployee,
      );
      if (!employeeDayState) {
        return null;
      }

      return employeeDayState;
    },
  );

export const selectEmployeeStateStartedWorkRecordForCurrentDay = createSelector(
  selectEmployeesState,
  selectEmployeeStateCurrentDay,
  selectTimeStateSystemTime,
  (state, currentDay, systemTime): IWorkRecord | null => {
    const {currentEmployee, data} = state;
    if (!currentEmployee || !currentDay || !data) return null;

    const currentEmployeeDayState = currentDay.employees.find(
      (employee) => employee.employeeId === currentEmployee,
    );
    if (!currentEmployeeDayState) return null;

    if (
      currentEmployeeDayState.workrecords &&
      currentEmployeeDayState.workrecords.length > 0
    ) {
      let openWorkRecord: IWorkRecord | undefined = undefined;
      currentEmployeeDayState.workrecords.forEach((workRecord) => {
        data.workRecords.find((record) => {
          if (+record.id! === workRecord && !record.end_time) {
            openWorkRecord = record;
            return;
          }
        });
      });

      if (!openWorkRecord) return null;
      const startTime = new Date((openWorkRecord as IWorkRecord).start_time);

      if (isAfter(startTime, systemTime)) {
        return null;
      }

      return openWorkRecord;
    }

    return null;
  },
);

export const selectEmployeesStateCurrentDayWorkMinutes = createSelector(
  selectEmployeesStateCurrentDayStateForCurrentEmployee,
  selectEmployeesStateData,
  selectTimeStateSystemTime,
  (currentDayState, data, systemTime): number => {
    let workMinutes = 0;
    if (
      !currentDayState ||
      !currentDayState.workrecords ||
      currentDayState.workrecords.length === 0 ||
      currentDayState.absence
    )
      return workMinutes;

    let workRecords = data.workRecords.filter((record) =>
      currentDayState!.workrecords!.includes(+record.id!),
    );

    if (workRecords.length === 0) return workMinutes;

    workRecords.forEach((workRecord) => {
      const startTime = new Date(workRecord.start_time);
      const endTime = workRecord.end_time
        ? new Date(workRecord.end_time)
        : null;

      if (isAfter(startTime, systemTime)) return;

      if (endTime) {
        if (isBefore(systemTime, endTime)) {
          workMinutes +=
            (systemTime.getTime() - startTime.getTime()) / (1000 * 60);
        } else {
          workMinutes +=
            (endTime.getTime() - startTime.getTime()) / (1000 * 60);
        }
      } else {
        let workMinutesOpenWorkRecord =
          (systemTime.getTime() - startTime.getTime()) / (1000 * 60);

        if (data.workingTimeRegulation) {
          const coreEndTime = new Date(systemTime);
          coreEndTime.setHours(
            data.workingTimeRegulation.endHour,
            data.workingTimeRegulation.endMinute,
            0,
            0,
          );

          if (isAfter(systemTime, coreEndTime)) {
            workMinutesOpenWorkRecord =
              (coreEndTime.getTime() - startTime.getTime()) / (1000 * 60);
          }
        }

        workMinutes += workMinutesOpenWorkRecord;
      }
    });

    workMinutes = Math.floor(workMinutes);

    workMinutes = workMinutes > 600 ? 600 : workMinutes;

    return workMinutes;
  },
);

export const selectCurrentEmployeeStartDate = createSelector(
  selectEmployeesState,
  (state) => {
    const {currentEmployee, data} = state;
    if (!currentEmployee) return null;

    const employee = data.employees.find(
      (employee) => +employee.employee.id === currentEmployee,
    );
    if (!employee) return null;

    return employee.workStartDate;
  },
);
export const selectCurrentEmployeeEndDate = createSelector(
  selectEmployeesState,
  (state): Date | null => {
    const {currentEmployee, data} = state;
    if (!currentEmployee) return null;

    const employeeState = data.employees.find(
      (employee) => +employee.employee.id === currentEmployee,
    );
    if (!employeeState) return null;

    let workTimeSchemes: IWorkTimeScheme[] = data.workTimeSchemes.filter(
      (scheme) => scheme.employee_id === currentEmployee,
    );

    let exitDate: Date | null = null;

    if (workTimeSchemes.length > 0) {
      workTimeSchemes
        .filter((scheme) => scheme.start_date)
        .sort(
          (a, b) =>
            new Date(a.start_date!).getTime() -
            new Date(b.start_date!).getTime(),
        )
        .forEach((scheme) => {
          if (scheme.start_date && scheme.end_date) {
            let endDate = new Date(scheme.end_date);
            if (!exitDate || isAfter(endDate, exitDate)) {
              exitDate = endDate;
            }
          }

          if (scheme.start_date && !scheme.end_date) {
            let startDate = new Date(scheme.start_date);
            if (!exitDate || isAfter(startDate, exitDate)) {
              exitDate = null;
            }
          }
        });
    }

    if (employeeState.employee.exitDate && !exitDate) {
      exitDate = new Date(employeeState.employee.exitDate);
    }

    return exitDate;
  },
);

export const selectIsWeekend = createSelector(
  selectEmployeeStateCurrentDay,
  (currentDay) => {
    if (!currentDay) return false;
    return currentDay.isWeekend;
  },
);

export const selectEmployeeStateCoreStartTime = createSelector(
  selectEmployeesStateData,
  selectTimeStateSystemTime,
  (data, systemTime): Date | null => {
    if (!data || !data.workingTimeRegulation) return null;
    let coreStartTime = new Date(systemTime);
    coreStartTime.setHours(data.workingTimeRegulation.startHour);
    coreStartTime.setMinutes(data.workingTimeRegulation.startMinute);
    return coreStartTime;
  },
);

export const selectEmployeeStateCoreEndTime = createSelector(
  selectEmployeesStateData,
  selectTimeStateSystemTime,
  (data, systemTime): Date | null => {
    if (!data || !data.workingTimeRegulation) return null;
    let coreEndTime = new Date(systemTime);
    coreEndTime.setHours(data.workingTimeRegulation.endHour);
    coreEndTime.setMinutes(data.workingTimeRegulation.endMinute);
    return coreEndTime;
  },
);

export const selectIsHoliday = createSelector(
  selectEmployeeStateCurrentDay,
  (currentDay): boolean => {
    if (!currentDay) return false;

    return currentDay.holiday ? true : false;
  },
);

export const selectFirstWorkTimeScheme = createSelector(
  selectEmployeesStateData,
  selectCurrentEmployeeID,
  (data, currentEmployee) => {
    if (!data || !currentEmployee) return null;
    let workTimeSchemes: IWorkTimeScheme[] = data.workTimeSchemes.filter(
      (scheme) => scheme.employee_id === currentEmployee,
    );

    if (workTimeSchemes.length === 0) return null;

    let workTimeScheme = workTimeSchemes.reduce((oldest, current) => {
      return current.start_date &&
        oldest.start_date &&
        new Date(current.start_date) < new Date(oldest.start_date)
        ? current
        : oldest;
    });

    return workTimeScheme;
  },
);

export const selectCarryOverRecord = createSelector(
  selectEmployeesStateData,
  selectCurrentEmployeeID,
  (data, currentEmployee): ICarryOverRecord | null => {
    if (!data || !currentEmployee) return null;
    let carryOverRecord: ICarryOverRecord | undefined =
      data.carryOverRecords.find(
        (record) => record.employee_id === currentEmployee,
      );

    if (!carryOverRecord) return null;

    return carryOverRecord;
  },
);

export const selectEmployeeStateCurrentWorkTimeSchemeCurrentEmployee =
  createSelector(
    selectEmployeesStateData,
    selectCurrentEmployeeID,
    selectTimeStateSystemTime,
    (data, currentEmployee, systemTime): IWorkTimeScheme | null => {
      if (!data || !currentEmployee) return null;
      let workTimeSchemes: IWorkTimeScheme[] = data.workTimeSchemes.filter(
        (scheme) => scheme.employee_id === currentEmployee,
      );

      if (workTimeSchemes.length === 0) return null;

      let workTimeScheme = workTimeSchemes.find(
        (scheme) =>
          scheme.start_date &&
          new Date(scheme.start_date!) <= systemTime &&
          (!scheme.end_date || new Date(scheme.end_date) >= systemTime),
      );

      return workTimeScheme ? workTimeScheme : null;
    },
  );

export const selectEmployeeStateCurrentWorkTimeSchemeSelectedUser =
  createSelector(
    selectEmployeesStateData,
    selectSelectedUser,
    selectTimeStateSystemTime,
    (data, selectedUser, systemTime): IWorkTimeScheme | null => {
      if (!data || !selectedUser) return null;
      let workTimeSchemes: IWorkTimeScheme[] = data.workTimeSchemes.filter(
        (scheme) => scheme.employee_id === selectedUser.id,
      );

      if (workTimeSchemes.length === 0) return null;

      let workTimeScheme = workTimeSchemes.find(
        (scheme) =>
          scheme.start_date &&
          new Date(scheme.start_date) <= systemTime &&
          (!scheme.end_date || new Date(scheme.end_date) >= systemTime),
      );

      return workTimeScheme ? workTimeScheme : null;
    },
  );

export const selectEmployeesStateCarryOverRecords = createSelector(
  selectCarryOverRecord,
  (carryOverRecord): {vacations: number; overtime: number} => {
    let data = {
      vacations: 0,
      overtime: 0,
    };

    if (!carryOverRecord) return data;

    data.vacations = carryOverRecord.vacations ? carryOverRecord.vacations : 0;
    data.overtime = carryOverRecord.overtime ? carryOverRecord.overtime : 0;

    return data;
  },
);

export const selectEmployeesStateAlias = createSelector(
  selectCurrentEmployeeID,
  selectAliasView,
  selectEmployeesStateData,
  (employee, aliasView, data): IUser | null => {
    let alias: IUser | null = null;
    if (!employee || !aliasView || !data) return alias;

    let employeeState: IEmployeeState | undefined = data.employees.find(
      (employeeData) => +employeeData.employee.id === employee,
    );

    return employeeState ? employeeState.employee : null;
  },
);

export const selectEmployeesStateCurrentEmployee = createSelector(
  selectCurrentEmployeeID,
  selectEmployeesStateData,
  (employee, data): IEmployeeState | null => {
    let alias: IUser | null = null;
    if (!employee || !data) return alias;

    let employeeState: IEmployeeState | undefined = data.employees.find(
      (employeeData) => +employeeData.employee.id === employee,
    );

    return employeeState ? employeeState : null;
  },
);

export const selectEmployeesStateWorkTimeRegulation = createSelector(
  selectEmployeesStateData,
  (data): IWorkingTimeRegulation | null => {
    return data.workingTimeRegulation ? data.workingTimeRegulation : null;
  },
);
