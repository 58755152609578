import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {Store} from '@ngrx/store';
import {map, Observable} from 'rxjs';
import {selectAccount} from '../store/account/reducer';
import {EPasswordReset} from '../enums/password-reset.enum';

export const MenuDeactivateGuard: CanDeactivateFn<any> = (
  component: any,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState?: RouterStateSnapshot,
): Observable<boolean> | Promise<boolean> | boolean => {
  return inject(Store)
    .select(selectAccount)
    .pipe(
      map((account) => {
        return account === null;
      }),
    );
};
