import {createFeature, createReducer, on} from '@ngrx/store';
import {IAccountState} from './types/account-state.interface';
import {accountActions} from './actions';
import {tr} from 'date-fns/locale';

const initialState: IAccountState = {
  account: null,
  authData: null,
  loading: false,
};

const accountFeature = createFeature({
  name: 'account',
  reducer: createReducer(
    initialState,
    on(accountActions.startLogin, (state) => ({
      ...state,
      loading: true,
    })),
    on(accountActions.autoLogin, (state) => ({
      ...state,
      loading: false,
    })),
    on(accountActions.fetchAccount, (state, action) => ({
      ...state,
      authData: action.authData,
    })),
    on(accountActions.loginSuccess, (state, action) => ({
      ...state,
      account: action.account,
      loading: false,
    })),
    on(accountActions.refreshAuthDataSuccess, (state, action) => ({
      ...state,
      authData: action.authData,
    })),
    on(accountActions.updatePassword, (state) => ({
      ...state,
      loading: true,
    })),
    on(accountActions.updatePasswordFetchAccount, (state, action) => ({
      ...state,
      authData: action.authData,
    })),
    on(accountActions.updatePasswordSuccess, (state, action) => ({
      ...state,
      account: action.account,
      loading: false,
    })),
    on(accountActions.updateAccount, (state) => ({
      ...state,
      loading: true,
    })),
    on(accountActions.updateAccountSuccess, (state, action) => ({
      ...state,
      account: action.account,
      loading: false,
    })),
    on(accountActions.updateAccountFailure, (state) => ({
      ...state,
      loading: false,
    })),
    on(accountActions.updatePasswordFailure, (state) => initialState),
    on(accountActions.loginFailure, (state) => initialState),
    on(accountActions.sessionExpired, (state) => initialState),
    on(accountActions.startLogout, (state) => ({
      ...state,
      loading: true,
    })),
    on(accountActions.logoutSuccess, (state) => initialState),
  ),
});

export const {
  name: accountFeatureKey,
  reducer: accountReducer,
  selectAccount,
  selectAuthData,
  selectLoading: selectAccountLoading,
} = accountFeature;
