/// <reference types="@angular/localize" />

import {bootstrapApplication} from '@angular/platform-browser';
import {
  RouteReuseStrategy,
  provideRouter,
  withPreloading,
  PreloadAllModules,
} from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import {StoreModule} from '@ngrx/store';
import {provideStoreDevtools} from '@ngrx/store-devtools';

import {routes} from './app/app.routes';
import {AppComponent} from './app/app.component';
import {
  APP_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import {provideServiceWorker} from '@angular/service-worker';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  provideRouterStore,
  routerReducer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicStorageModule} from '@ionic/storage-angular';
import {Drivers} from '@ionic/storage';
import {
  LoggerModule,
  NgxLoggerLevel,
  TOKEN_LOGGER_WRITER_SERVICE,
} from 'ngx-logger';
import {LoggerWriterService} from 'src/app/shared/services/logger-writer.service';
import {EffectsModule} from '@ngrx/effects';
import {
  accountFeatureKey,
  accountReducer,
} from './app/shared/store/account/reducer';
import {
  settingsFeatureKey,
  settingsReducer,
} from './app/shared/store/settings/reducer';
import {
  systemTimeFeatureKey,
  systemTimeReducer,
} from './app/shared/store/time/reducer';
import {SettingsFeatureEffects} from './app/shared/store/settings/effects';
import {TimeFeatureEffects} from './app/shared/store/time/effects';
import {FlowControlService} from './app/shared/services/flow-control.service';
import {
  employeesFeatureKey,
  employeesReducer,
} from './app/shared/store/employees/reducer';
import {EmployeesFeatureEffects} from './app/shared/store/employees/effects';
import {menuFeatureKey, menuReducer} from './app/features/menu/store/reducer';
import {AccountFeatureEffect} from './app/shared/store/account/effects';
import {authInterceptor} from './app/shared/services/auth-interceptor.service';
import {
  alertFeatureKey,
  alertReducer,
} from './app/features/alert/store/reducer';
import {CustomErrorHandler} from './app/shared/services/custom-error-handler.service';
import {
  splashScreenFeatureKey,
  splashScreenReducer,
} from './app/features/splash-screen/store/reducer';
import {APIFeatureEffect} from './app/shared/store/api/efffects';
import {AlertFeatureEffect} from './app/features/alert/store/effects';
import {usersFeatureKey, usersReducer} from './app/shared/store/users/reducer';
import {UsersFeatureEffect} from './app/shared/store/users/effects';
import {
  workRecordFeatureKey,
  workRecordReducer,
} from './app/shared/store/work-record/reducer';
import {WorkRecordsFeatureEffects} from './app/shared/store/work-record/effects';
import {
  workTimeSchemeFeatureKey,
  workTimeSchemeReducer,
} from './app/shared/store/worktimescheme/reducer';
import {WorkTimeSchemeFeatureEffects} from './app/shared/store/worktimescheme/effects';
import {
  absenceFeatureKey,
  absenceReducer,
} from './app/shared/store/absence/reducer';
import {AbsenceFeatureEffects} from './app/shared/store/absence/effects';
import {environment} from './environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: APP_INITIALIZER,
      useFactory: (flowControl: FlowControlService) => () => {
        flowControl.appStart();
      },
      multi: true,
      deps: [FlowControlService],
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    provideIonicAngular({
      mode: 'ios',
      rippleEffect: false,
      scrollAssist: false,
      swipeBackEnabled: false,
    }),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !environment.apiUrl.includes('localhost'),
      registrationStrategy: 'registerWhenStable:10000',
    }),
    importProvidersFrom(
      StoreModule.forRoot({router: routerReducer}),
      StoreModule.forFeature(accountFeatureKey, accountReducer),
      StoreModule.forFeature(settingsFeatureKey, settingsReducer),
      StoreModule.forFeature(systemTimeFeatureKey, systemTimeReducer),
      StoreModule.forFeature(alertFeatureKey, alertReducer),
      StoreModule.forFeature(employeesFeatureKey, employeesReducer),
      StoreModule.forFeature(menuFeatureKey, menuReducer),
      StoreModule.forFeature(splashScreenFeatureKey, splashScreenReducer),
      StoreModule.forFeature(usersFeatureKey, usersReducer),
      StoreModule.forFeature(workRecordFeatureKey, workRecordReducer),
      StoreModule.forFeature(workTimeSchemeFeatureKey, workTimeSchemeReducer),
      StoreModule.forFeature(absenceFeatureKey, absenceReducer),
      EffectsModule.forRoot({}),
      EffectsModule.forFeature([
        SettingsFeatureEffects,
        TimeFeatureEffects,
        EmployeesFeatureEffects,
        AccountFeatureEffect,
        APIFeatureEffect,
        AlertFeatureEffect,
        UsersFeatureEffect,
        WorkRecordsFeatureEffects,
        WorkTimeSchemeFeatureEffects,
        AbsenceFeatureEffects,
      ]),
      FontAwesomeModule,
      StoreRouterConnectingModule.forRoot(),
      TranslateModule.forRoot({
        defaultLanguage: 'de',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      IonicStorageModule.forRoot({
        name: '__neobit',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      }),
      LoggerModule.forRoot(
        {
          level: NgxLoggerLevel.TRACE,
          serverLogLevel: NgxLoggerLevel.OFF,
        },
        {
          writerProvider: {
            provide: TOKEN_LOGGER_WRITER_SERVICE,
            useClass: LoggerWriterService,
          },
        },
      ),
    ),
    provideStoreDevtools({
      maxAge: 50,
      autoPause: true,
      trace: false,
      logOnly: !isDevMode(),
    }),
  ],
});
