import {ca} from 'date-fns/locale';
import {EUserRole} from '../enums/user-role.enum';
import {IEmployeesDataState} from '../store/employees/types/employees-data-state';
import {IUser} from '../types/user.interface';

export function convertUTCDateTimeToLocal(utcTime: string) {
  var date;
  if (utcTime.includes('Z')) {
    date = new Date(utcTime);
  } else {
    date = new Date(utcTime + ' UTC');
  }
  return date;
}

export function getLocalizedDayName(
  date: Date,
  type: 'short' | 'long',
  languageCode: string = 'de-DE',
) {
  return date.toLocaleDateString(languageCode, {weekday: type});
}

export function getLocalizedMonthName(
  month: number,
  type: 'short' | 'long' = 'long',
  languageCode: string = 'de-DE',
): string {
  let date = new Date();
  date.setMonth(month - 1, 1);
  let monthName = new Date(date).toLocaleString(languageCode, {
    month: type,
  });
  return monthName;
}

export function getLocalizedMonthNameWithYear(
  month: number,
  year: number,
  type: 'short' | 'long' = 'long',
  languageCode: string = 'de-DE',
): string {
  let date = new Date();
  date.setFullYear(year);
  date.setMonth(month - 1, 1);
  let monthName = new Date(date).toLocaleString(languageCode, {
    month: type,
    year: 'numeric',
  });
  return monthName;
}

export function isAdmin(user: IUser | null): boolean {
  if (!user) return false;
  return user.role === EUserRole.ADMINISTRATOR;
}

export function isBoard(user: IUser | null): boolean {
  if (!user) return false;
  return user.role === EUserRole.BOARD;
}
export function isManager(user: IUser | null): boolean {
  if (!user) return false;
  return user.role === EUserRole.MANAGER;
}
export function isEmployee(user: IUser | null): boolean {
  if (!user) return false;
  return (
    user.role === EUserRole.MANAGER ||
    user.role === EUserRole.EMPLOYEE ||
    user.role === EUserRole.TRAINEE
  );
}

export function isEducator(user: IUser | null): boolean {
  if (!user) return false;
  return user.role === EUserRole.EMPLOYEE || user.role === EUserRole.TRAINEE;
}

export function isParent(user: IUser | null): boolean {
  if (!user) return false;
  return user.role === EUserRole.PARENT;
}

export function convertApiEmployeeData(
  data: IEmployeesDataState,
): IEmployeesDataState {
  data.employees.forEach((employeeState: any) => {
    employeeState.employee.entryDate = employeeState.employee.entryDate
      ? convertUTCDateTimeToLocal(employeeState.employee.entryDate)
      : employeeState.employee.entryDate;
    employeeState.employee.exitDate = employeeState.employee.exitDate
      ? convertUTCDateTimeToLocal(employeeState.employee.exitDate)
      : employeeState.employee.exitDate;
  });

  data.workRecords.forEach((workRecord: any) => {
    workRecord.start_time = convertUTCDateTimeToLocal(workRecord.start_time);
    workRecord.end_time = workRecord.end_time
      ? convertUTCDateTimeToLocal(workRecord.end_time)
      : workRecord.end_time;
  });

  data.absences.forEach((absenceState: any) => {
    absenceState.absence.start_date = convertUTCDateTimeToLocal(
      absenceState.absence.start_date,
    );
    absenceState.absence.end_date = convertUTCDateTimeToLocal(
      absenceState.absence.end_date,
    );
  });

  data.workTimeSchemes.forEach((workTimeScheme: any) => {
    workTimeScheme.start_date = workTimeScheme.start_date
      ? convertUTCDateTimeToLocal(workTimeScheme.start_date)
      : workTimeScheme.start_date;

    workTimeScheme.end_date = workTimeScheme.end_date
      ? convertUTCDateTimeToLocal(workTimeScheme.end_date)
      : workTimeScheme.end_date;

    workTimeScheme.start_time = workTimeScheme.start_time
      ? {
          hours: Number(workTimeScheme.start_time.split(':')[0]),
          minutes: Number(workTimeScheme.start_time.split(':')[1]),
          seconds: Number(workTimeScheme.start_time.split(':')[2]),
        }
      : workTimeScheme.start_time;

    workTimeScheme.end_time = workTimeScheme.end_time
      ? {
          hours: Number(workTimeScheme.end_time.split(':')[0]),
          minutes: Number(workTimeScheme.end_time.split(':')[1]),
          seconds: Number(workTimeScheme.end_time.split(':')[2]),
        }
      : workTimeScheme.end_time;

    workTimeScheme.working_hours = workTimeScheme.working_hours
      ? Number(workTimeScheme.working_hours)
      : workTimeScheme.working_hours;
  });

  data.carryOverRecords.forEach((carryOverRecord: any) => {
    carryOverRecord.overtime = carryOverRecord.overtime
      ? Number(carryOverRecord.overtime)
      : carryOverRecord.overtime;
  });

  return data;
}
