import {Injectable} from '@angular/core';
import {
  NGXLoggerWriterService,
  INGXLoggerMetadata,
  INGXLoggerConfig,
} from 'ngx-logger';
import {format} from 'date-fns-tz';
import {environment} from 'src/environments/environment';
import {Store} from '@ngrx/store';
import {settingsActions} from '../store/settings/actions';

@Injectable()
export class LoggerWriterService extends NGXLoggerWriterService {
  constructor(private store: Store) {
    super(0);
  }

  /** Write the content sent to the log function to the console */
  public override writeMessage(
    metadata: INGXLoggerMetadata,
    config: INGXLoggerConfig,
  ): void {
    const date = new Date(metadata.timestamp!);
    let timestamp = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSX", {
      timeZone: 'Europe/Berlin',
    });
    const log: INGXLoggerMetadata = {
      ...metadata,
      timestamp: timestamp,
      columnNumber:
        metadata.columnNumber == undefined ? metadata.columnNumber : 0,
    };
    this.store.dispatch(settingsActions.addNewLog({log: log}));

    if (
      environment.apiUrl.includes('localhost') ||
      environment.apiUrl.includes('testing') ||
      environment.apiUrl.includes('staging')
    ) {
      console.log(log.message);
    }
  }
}
