import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ITimeState} from '../types/time.interface';
import {format, getUnixTime} from 'date-fns';
import {selectCustomTimeDiff, selectServerTime} from '../reducer';
import {selectUseCustomTime} from '../../settings/selectors/settings.selectors';

export const selectTimeState = createFeatureSelector<ITimeState>('systemTime');

export const selectTimeStateSystemTime = createSelector(
  selectServerTime,
  selectUseCustomTime,
  selectCustomTimeDiff,
  (serverTime, useCustomTime, customTimeDiff) => {
    if (!serverTime) return new Date();

    let date = new Date(serverTime);

    if (useCustomTime) {
      date = new Date((getUnixTime(date) + customTimeDiff) * 1000);
    }

    return date;
  },
);

export const selectTimeStateCurrentYear = createSelector(
  selectTimeStateSystemTime,
  (systemTime): number => {
    if (!systemTime) return new Date().getFullYear();
    return systemTime.getFullYear();
  },
);

export const selectTimeStateCurrentMonth = createSelector(
  selectTimeStateSystemTime,
  (systemTime) => {
    if (!systemTime) return new Date().getMonth();
    return systemTime.getMonth() + 1;
  },
);

export const selectTimeStateSystemClock = createSelector(
  selectTimeState,
  (state) => {
    if (!state.serverClock) return new Date();

    let date = new Date(state.serverClock);

    if (state.useCustomTime) {
      date = new Date((getUnixTime(date) + state.customTimeDiff) * 1000);
    }

    return date;
  },
);
