import {createFeature, createReducer, on} from '@ngrx/store';
import {usersActions} from './actions';
import {IUsersState} from './types/users-state.interface';
const initialState: IUsersState = {
  users: [],
  selectedUser: null,
  isLoading: false,
};

const usersFeature = createFeature({
  name: 'users',
  reducer: createReducer(
    initialState,
    on(usersActions.fetchUsers, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(usersActions.fetchUsersSuccess, (state, action) => ({
      ...state,
      users: action.users,
      isLoading: false,
    })),
    on(usersActions.fetchUsersFailure, (state) => ({
      ...state,
      users: [],
      isLoading: false,
    })),
    on(usersActions.selectUser, (state, action) => ({
      ...state,
      selectedUser: action.user,
    })),
    on(usersActions.removeSelectedUser, (state) => ({
      ...state,
      selectedUser: null,
    })),
    on(usersActions.addUser, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(usersActions.addUserSuccess, (state, action) => ({
      ...state,
      users: [...state.users, action.user],
      isLoading: false,
    })),
    on(usersActions.addUserFailure, (state) => ({
      ...state,
      isLoading: false,
    })),
    on(usersActions.updateUser, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(usersActions.updateUserSuccess, (state, action) => ({
      ...state,
      users: state.users.map((user) =>
        user.id === action.user.id ? action.user : user,
      ),
      selectedUser: action.user,
      isLoading: false,
    })),
    on(usersActions.updateUserFailure, (state) => ({
      ...state,
      isLoading: false,
    })),
    on(usersActions.deleteUser, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(usersActions.deleteUserSuccess, (state, action) => ({
      ...state,
      users: state.users.filter((user) => user.id !== action.id),
      selectedUser: null,
      isLoading: false,
    })),
    on(usersActions.deleteUserFailure, (state) => ({
      ...state,
      isLoading: false,
    })),
  ),
});

export const {
  name: usersFeatureKey,
  reducer: usersReducer,
  selectUsersState,
  selectUsers,
  selectSelectedUser,
  selectIsLoading: selectUsersStateIsLoading,
} = usersFeature;
