import {createFeature, createReducer, on} from '@ngrx/store';
import {IAlertState} from './types/alert-state.interface';
import {alertActions} from './actions';
import {EMessageType} from 'src/app/shared/enums/message-type.enum';

const initialState: IAlertState = {
  id: null,
  message: '',
  type: EMessageType.UNDEFINED,
  isOpen: false,
};

const alertFeature = createFeature({
  name: 'alert',
  reducer: createReducer(
    initialState,
    on(alertActions.showAlert, (state, action) => ({
      ...state,
      message: action.message,
      type: action.messageType,
      id: action.id ? action.id : null,
      isOpen: true,
    })),
    on(alertActions.showAlertWithInput, (state, action) => ({
      ...state,
      message: action.message,
      type: action.messageType,
      id: action.id ? action.id : null,
      isOpen: true,
    })),
    on(alertActions.closeAlert, (state) => ({
      ...state,
      isOpen: false,
    })),
    on(alertActions.reset, (state) => initialState),
  ),
});

export const {
  name: alertFeatureKey,
  reducer: alertReducer,
  selectAlertState,
  selectMessage: selectAlertMessage,
  selectType: selectAlertType,
  selectIsOpen: selectAlertIsOpen,
} = alertFeature;
