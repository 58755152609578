import {createFeature, createReducer, on} from '@ngrx/store';
import {ITimeState} from './types/time.interface';
import {timeActions} from './actions';

const initialState: ITimeState = {
  serverTime: new Date(),
  serverClock: new Date(),
  customTimeDiff: 0,
  useCustomTime: false,
  isLoading: false,
};

const systemTimeFeature = createFeature({
  name: 'systemTime',
  reducer: createReducer(
    initialState,
    on(timeActions.fetchTime, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(timeActions.fetchTimeSuccess, (state, action) => ({
      ...state,
      serverTime: action.date.systemTime,
      serverClock: action.date.systemTime,
      customTimeDiff: action.date.customTimeDiff,
      isLoading: false,
    })),
    on(timeActions.setServerTime, (state, action) => ({
      ...state,
      serverTime: action.date,
      serverClock: action.date,
    })),
    on(timeActions.setServerClockTime, (state, action) => ({
      ...state,
      serverClock: action.date,
    })),
    on(timeActions.setTimeAfterCustomTimeUpdate, (state, action) => ({
      ...state,
      serverTime: action.date.systemTime,
      serverClock: action.date.systemTime,
      customTimeDiff: action.date.customTimeDiff,
    })),
    on(timeActions.setCustomTimeDiff, (state, action) => ({
      ...state,
      customTimeDiff: action.timeDiff,
    })),
    on(timeActions.resetCustomTime, (state) => ({
      ...state,
      customTimeDiff: 0,
    })),
    on(timeActions.useCustomTime, (state, action) => ({
      ...state,
      useCustomTime: action.useCustomTime,
    })),
    on(timeActions.failedFetching, (state) => ({
      ...state,
      initialState,
    })),
  ),
});

export const {
  name: systemTimeFeatureKey,
  reducer: systemTimeReducer,
  selectServerTime,
  selectServerClock,
  selectSystemTimeState,
  selectCustomTimeDiff,
  selectIsLoading: selectSystemTimeIsLoading,
} = systemTimeFeature;
